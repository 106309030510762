import React from 'react'
import { useForm } from '../../hooks/useForm';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { startRegister } from '../../actions/auth';
import { NavLink } from 'react-router-dom'

import { GetUser } from '../../selectors/getUsers';

export const NewUser = () => {

    const { role, uid } = useSelector( state => state.auth );
    
    let usuarios = GetUser();
    usuarios = useSelector(state => state.paises.users);

    let userAdmin;
    try {
        userAdmin = usuarios.find(user => user.id === uid);
    } catch (error) {
        console.log("Hi");
    }
    //console.log(userAdmin);

    const dispatch = useDispatch();
    const [ formRegisterValues, handleRegisterInputChange ] = useForm({
        rName: null,
        rEmail: null,
        rPassword1: null,
        rPassword2: null,
        rRole:null,
        rShift:null,
        rBreak:null,
        rDayOff:null,
    });

    let { rName, rEmail, rPassword1, rPassword2, rRole, rShift, rBreak, rDayOff} = formRegisterValues;
    
    const handleRegister = ( e ) => {
        e.preventDefault();

        if ( rPassword1 !== rPassword2 ) {
            return Swal.fire('Error', 'Passwords must be the same','error');
        }
        if(rRole === "" || rRole === null){
            rRole = "Agente"
        }
        if(rShift === "" || rShift === null){
            rShift = "morning"
        }
        if(rBreak === "" || rBreak === null){
            rBreak = "7:00"
        }
        console.log( rEmail, rPassword1, rName, rRole, "Active", rShift ,userAdmin.hub, rBreak, rDayOff)
        dispatch( startRegister( rEmail, rPassword1, rName, rRole, "Active", rShift, userAdmin.hub, rBreak, rDayOff) );
    }

    return (
        <div className="container login-container mt-0 mx-auto">
            <hr />
            <div className="container-fliud center text-center">
                {(role==="Admin" || role==="RH")&&
                    <div>
                        <h1 className="text-center" style={{ color: '	#FFFFFF' }}>USERS</h1>
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-secondary">
                                <NavLink to={`../../users`} className="stretched-link font-weight-bold" style={{ color: '#d9b54a'}}><i class="fas fa-undo-alt"></i> Back</NavLink>
                            </button>
                        </div>
                    </div>
                }
                {(role==="Agente" || role==="AgenteDB"  || role==="CoordQA" || role==="CoordDB" || role==="CoordCD" || role==="PD Admin")&&
                    <div>
                        <h1 className="text-center" style={{ color: '#FFFFFF' }}>Access Denied</h1>
                    </div>
                }
                <hr />

            </div>
            {(role==="Admin" || role==="RH")&&
                <div className="container login-container col login-form-1 mt-0">
                    <h3 style={{ color: '#283040' }}>Create account</h3>
                    <form onSubmit={ handleRegister }>
                    <p>Name:</p>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                name="rName"
                                value={ rName }
                                onChange={ handleRegisterInputChange }
                            />
                        </div>
                        <p>Email:</p>
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                name="rEmail"
                                value={ rEmail }
                                onChange={ handleRegisterInputChange }
                            />
                        </div>
                        <p>Role:</p> 
                        { (role==="RH")&&
                        <div className="form-group"> 
                            <select className="form-control form-select" 
                                aria-label="Default select example"
                                name="rRole"
                                value={ rRole }
                                onChange={ handleRegisterInputChange }>
                                    <option value="Agente">Agent</option>
                                    <option value="RH">RH</option>
                            </select>
                        </div>
                        }
                        {(role==="Admin")&&
                        <div className="form-group"> 
                            <select className="form-control form-select" 
                                aria-label="Default select example"
                                name="rRole"
                                value={ rRole }
                                onChange={ handleRegisterInputChange }>
                                    <option value="Agente">Agent</option>
                                    <option value="CoordQA">Coord. QA</option>
                                    <option value="CoordCD">Coord. CD</option>
                                    <option value="RH">RH</option>
                                    <option value="Admin">Admin</option>
                                    {/*<option value="PD Admin"> PD Admin</option>*/}
                                    <option value="Productivity"> Productivity</option>
                            </select>
                        </div>
                        }
                        <p>Password:</p>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Password" 
                                name="rPassword1"
                                value={ rPassword1 }
                                onChange={ handleRegisterInputChange }
                            />
                        </div>
                        <p>Confirm password:</p>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Comfirm Password" 
                                name="rPassword2"
                                value={ rPassword2 }
                                onChange={ handleRegisterInputChange }
                            />
                        </div>
                        <p>Shift:</p>
                        <div className="form-group"> 
                            <select className="form-control form-select" 
                                aria-label="Default select example"
                                name="rShift"
                                value={ rShift }
                                onChange={ handleRegisterInputChange }>
                                    <option value="morning">morning</option>
                                    <option value="midday 09:00">midday 09:00</option>
                                    <option value="midday 10:00">midday 10:00</option>
                                    <option value="afternoon">afternoon</option>
                                    <option value="night">night</option>
                                    <option value="RRHH">RRHH</option>
                                    <option value="QA morning">QA morning</option>
                                    <option value="QA midday">QA midday</option>
                                    <option value="QA afternoon">QA afternoon</option>
                                    <option value="QA night">QA night</option>
                                    <option value="No turn">No turn</option>
                            </select>
                        </div>
                        <p>Break:</p>
                        <div className="form-group"> 
                            <select className="form-control form-select" 
                                aria-label="Default select example"
                                name="rBreak"
                                value={ rBreak }
                                onChange={ handleRegisterInputChange }>
                                    <option value="7:00">7:00</option>
                                    <option value="8:00">8:00</option>
                                    <option value="9:00">9:00</option>
                                    <option value="10:00">10:00</option>
                                    <option value="11:00">11:00</option>
                                    <option value="12:00">12:00</option>
                                    <option value="13:00">13:00</option>
                                    <option value="14:00">14:00</option>
                                    <option value="15:00">15:00</option>
                                    <option value="16:00">16:00</option>
                                    <option value="17:00">17:00</option>
                                    <option value="18:00">18:00</option>
                                    <option value="19:00">19:00</option>
                                    <option value="20:00">20:00</option>
                                    <option value="21:00">21:00</option>
                                    <option value="22:00">22:00</option>
                                    <option value="No break">No Break</option>
                            </select>
                        </div>
                        <p>Day Off:</p>
                        <div className="form-group"> 
                            <select className="form-control form-select" 
                                aria-label="Default select example"
                                name="rDayOff"
                                value={ rDayOff }
                                onChange={ handleRegisterInputChange }>
                                    <option value="Monday">Monday</option>
                                    <option value="Tuesday">Tuesday</option>
                                    <option value="Wednesday">Wednesday</option>
                                    <option value="Thursday">Thursday</option>
                                    <option value="Friday">Friday</option>
                                    <option value="Saturday">Saturday</option>
                                    <option value="Sunday">Sunday</option>
                                    <option value="No day off">No day off</option>
                                    
                            </select>
                        </div>
                        <div className="form-group">
                            <input 
                                type="submit" 
                                className="btnSubmit" 
                                value="Save" />
                        </div>
                    </form>
                </div>
            }
            
        </div>
    )
}
