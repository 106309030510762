import {combineReducers} from 'redux';
import { authReducer } from './authReducer';
import { checkReducer } from './checkReducer';
import { customReducer } from './customReducer';
import { paisReducer } from './paisReducer';
import { uiReducer } from './uiReducer';

export const rootReducer = combineReducers({
    auth: authReducer,
    ui: uiReducer,
    paises: paisReducer,
    custom: customReducer,
    check: checkReducer,
})