import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetUser } from '../../selectors/getUsers';
import MaterialTable from "material-table";
import { useState , useEffect } from "react";
import { updateCheckE } from '../../actions/checker';
import "./style.css"
import { GetCheckE } from '../../selectors/getCheck';
import moment from 'moment';
import axios from 'axios';
import Swal from "sweetalert2";
import Loader from '../Loader/Loader';
const baseUrl = process.env.REACT_APP_API_URL;

export const Overtimes = () => {

    const { role, name, uid } = useSelector( state => state.auth );
    console.log("role: ", role, " uid: "+uid);

    const dispatch = useDispatch();
    //----------------------------------------------
    let date = moment().tz("Asia/Calcutta").format("YYYY-MM-DD");
    const [dateFormat, setDateFormat]= useState(date);
    const [dateFormat2, setDateFormat2]= useState(date);
    const [loader, setLoader]= useState(true);//use state loader
    const[overtimes, setOvertimes]=useState ([]);
    const[status, setStatus] = useState ("Pending");
    const [btn, setBtn] = useState(false);//Search button
    const [btn2, setBtn2] = useState(false);//Refresh button

    let usuarios = GetUser();
    usuarios = useSelector(state => state.paises.users);
    
    let userAdmin;
    try {
        userAdmin = usuarios.find(user => user.id === uid);
    } catch (error) {
        console.log("Hi");
    }

    useEffect(async() => {
        
        console.log("-------- execute use effect --------")
        setLoader(true);
        const token = localStorage.getItem('token') || '';
        console.log(token);
        const dateArray=dateFormat.split('-');
        const dateArray2=dateFormat2.split('-');
        const dateToQuery=dateArray[1]+"-"+dateArray[2]+"-"+dateArray[0]
        const dateToQuery2=dateArray2[1]+"-"+dateArray2[2]+"-"+dateArray2[0]

        await axios.get(`${baseUrl}/payroll/extras/${dateToQuery}/${dateToQuery2}/${status}`, { headers: {"x-token" : `${token}`} })
                    .then(async res => 
                        {
                            console.log('profile is:', res.data);
                            setLoader(false);
                            await loopcheckover(res.data.result);
                        })
                    .catch(error => 
                            console.log(error
                        ))
        
    }, [btn])

    const onChangeDate = (e) =>{
        e.preventDefault();
        const dateArray=e.target.value.split("-");
        const currentDate = moment().tz("Asia/Calcutta");
        const queryDate= moment(dateArray[0]+""+dateArray[1]+""+(dateArray[2]));
        console.log("fecha construida: "+ queryDate)
        console.log("fecha actual: "+currentDate+" fecha consulta: "+queryDate); 
        if(queryDate>currentDate){
            Swal.fire('Error',"Unable to query a date in the future", 'error');
            return;
        }else{
            setDateFormat(e.target.value);
        }
    }

    const onChangeDate2 = (e) =>{
        e.preventDefault();
        const dateArray=e.target.value.split("-");;
        const currentDate = moment().tz("Asia/Calcutta");
        const queryDate= moment(dateArray[0]+""+dateArray[1]+""+(dateArray[2]));
        console.log("fecha construida: "+ queryDate)
        console.log("fecha actual: "+currentDate+" fecha consulta: "+queryDate); 
        if(queryDate>currentDate){
            Swal.fire('Error',"Unable to query a date in the future", 'error');
            return;
        }else{
            setDateFormat2(e.target.value);
        }
    }

    const onChangeStatus = (e) =>{
        e.preventDefault();
        console.log(e.target.value);
        setStatus(e.target.value);

    }

    /*Conf Button Search & Refresh*/
    const onChangeBtn = (e) => {
        e.preventDefault();
        setBtn((btn === true) ? false : true);
    }

    const onChangeBtn2 = (e) => {
        e.preventDefault();
        window.location.reload();
        setBtn2((btn === true) ? false : true);
    }
    /*---------*/

    //console.log(userAdmin);

    const loopcheckover= async(checker)=>{
        console.log(checker);
        const arrAux=[];
        checker.map(checkA => {
    
                            
                            const obj = {
                                
                                "name": checkA.name, 
                                "shift": checkA.shift,
                                "fecha": checkA.fechaExtra, 
                                "approval": checkA.approval,
                                "Supervisor": checkA.supervisor,
                                "total": checkA.total,
                                "id": checkA._id_extras,
                                "uid": checkA._id_user,
                                "checkin": checkA.checkin,
                                "checkout": checkA.checkout
                            };
                           arrAux.push(obj); 
                    
    }) 
    setOvertimes(arrAux);
    
    }
    

    const [columns] = useState([
        { title: 'Worker', field: 'name'},
        { title: 'Shift', field: 'shift'},
        { title: 'Date', field: 'fecha'},
        { title: 'CheckIn', field: 'checkin'},
        { title: 'CheckOut', field: 'checkout'},
        { title: 'Total Hrs', field: 'total'},
        { title: 'Approval', field: 'approval',
        lookup: { "Accepted": 'Accepted', "Rejected": 'Rejected', "Pending": 'Pending'}
        },
        
      ]);
    
    console.log("|"+role+"|");

    return (
        <div>
            <hr />
            <div className="container-fliud center text-center">
                {(role==="Admin"|| role==="Payroll" )&&
                    <div>
                        <h1 className="text-center" style={{ color: '#ffffff' }}>OVERTIME TO AUTHORIZE</h1>
                    </div>
                }
                {(role==="Agente" || role==="AgenteDB" || role==="RH" || role==="CoordQA" || role==="CoordDB" || role==="CoordCD")&&
                    <div>
                        <h1 className="text-center" style={{ color: '#ffffff' }}>Access Denied</h1>
                    </div>
                }
                <hr />
            </div>
            <div  className='row col-lg-7 mx-auto my-2'>
                        <div className='row col-lg-4 mx-auto' >
                            <label className='col-lg-12 text-white text-left'>From</label>
                            <input type="date" className ="form-control" name='FechaInicial' value={dateFormat} max={date} onChange={onChangeDate}>
                                
                            </input>
                        </div>
                        <div className='row col-lg-4 mx-auto'>
                        <label className='col-lg-12 text-white text-left'>To</label>
                            <input type="date" className ="form-control" name='FechaFinal' value={dateFormat2} max={date} onChange={onChangeDate2}>
                                
                            </input>

                        </div>

                        <div className='row col-lg-4 mx-auto' >
                            <label className='col-lg-12 text-white text-left'>Status</label>
                            <select className="form-control " name="status" value={status} onChange={ onChangeStatus } >
                                        {/* <option selected>Select Shift</option> */}
                                        <option value="Accepted"> Accepted </option>
                                        <option value="Rejected"> Rejected </option>
                                        <option value="Pending"> Pending </option>
                                       
                                </select>
            
                        </div>
                        
                    </div>

            <p></p>
            <div className='d-flex justify-content-center'>
                <button className="btn btn-outline-info" onClick={onChangeBtn}><i className="fa-solid fa-magnifying-glass"></i> Search</button> {/*Search Button*/}
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
                <button className="btn btn-outline-info" onClick={onChangeBtn2}><i class="fa-solid fa-rotate"></i> Refresh</button> {/*Refresh Button*/}
            </div>
            <p></p>


             {//---------------------------------------- start loader -------------------------------------
            
            (loader)&&
                <div className='col-lg-12 row'>
                    <div class="spinner-border text-white col-lg-4 mx-auto text-center" role="status" key={"status"}>
                        
                        <Loader/>
                        <h3>Loading...</h3>
                    </div>
                </div>
            //---------------------------------------- loader end ----------------------------------------
            } 

            {(role==="Admin" || role==="Payroll")&&(!loader)&&
                    <div> 
                    <MaterialTable
                        className="usuarios-table"
                        options={{
                            rowStyle:{backgroundColor:'#f2f2f2'},
                            headerStyle:{backgroundColor:'#f2f2f2'},
                            actionsCellStyle:{backgroundColor:'#f2f2f2'},
                            style:{backgroundColor:'#f2f2f2'}
                            }}
                        style={{backgroundColor:'#f2f2f2'}}
                        title=""
                        columns={columns}
                        data={overtimes}
                        
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                            new Promise(async(resolve, reject) => {
                                await setTimeout(async() => {
                                console.log(`newData ${newData}`);
                                const {id, total, approval,name} = newData;
                                let registro = await overtimes.find(checks=> checks.id === id);
                                console.log("registro",registro);
                                console.log(` id: ${id},  uid: ${registro.uid}, fecha: ${registro.fecha}, checkin: ${registro.checkin}, checkout: ${registro.checkout}, total: ${registro.total}, approval: ${approval} name: ${userAdmin.name} `)
                                
                                dispatch(updateCheckE( id, registro.uid, registro.fecha, registro.checkin, registro.checkout, registro.total, approval, userAdmin.name ));
                                
                                resolve();
                                /*- - - - Overtimes alerts by Yaelo - - - -*/
                                if(approval==="Accepted"){
                                    Swal.fire({
                                        icon: 'success',
                                        title: `Overtime of ${registro.name} was accepted`,
                                        showConfirmButton: false,
                                        timer: 3000
                                      })
                                } else if(approval==="Rejected"){
                                    Swal.fire({
                                        icon: 'error',
                                        title: `Overtime of ${registro.name} was rejected`,
                                        showConfirmButton: false,
                                        timer: 3000
                                      })
                                } else if(approval==="Pending"){
                                    Swal.fire({
                                        icon: 'info',
                                        title: `Overtime of ${registro.name} was returned to pending`,
                                        showConfirmButton: false,
                                        timer: 3000
                                      })
                                }
                                /*- - - - - - - - - - - - - - - - - - - - -*/

                                //history.go(0)
                                }, 1000)
                                
                            }),
                            onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                const index = oldData.tableData.id;
                                const dataUpdate = overtimes[index];
                                //console.log(dataUpdate);
                                //console.log(dataUpdate.id);
                                //dispatch(userDelete( dataUpdate.id));
                                resolve()
                                //history.go(0)
                                }, 1000)
                            }),
                        }}
                    />
                </div>
                }
            
        </div>
        
    )
}
