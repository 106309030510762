import { types } from "../types/types";

const initialState ={
    checking: true,
    uid: null,
    name: null,
    role: null,
    status: null,
    shift:null, 
    hub:null, 
    breakT:null,
    users: [],
    spread:[],
    spreadNom:null,
    spreadRH:null
}

export const authReducer = (state = initialState, action) => {

    switch(action.type){
        case types.authLogin:
            return {
                ...state,
                ...action.payload,
                checking: false
            }

        case types.authCheckingFinish:
            return {
                ...state,
                ...action.payload,
                checking: false
            }

        case types.authLogout:
            return {
                checking: false
            }
        case types.spreadLoaded:
            return {
                ...state,
                spread: [ ...action.payload ]
            }
        case types.spreadLoadedNom:
            return {
                ...state,
                spreadNom: [ ...action.payload ]
            }
        case types.spreadLoadedRH:
            return {
                ...state,
                spreadRH: [ ...action.payload ]
            }
        default:
            return state;
    }
}