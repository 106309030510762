import React from 'react';
import "./Loader.css"

const Loader = () => {
  return(
  <div>
      <div class="loadingio-spinner-wedges-lvg3siqmxif"><div class="ldio-n3f301ff53">
    <div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></div>
    </div></div>
  </div>
  );
};

export default Loader;
