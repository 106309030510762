import moment from 'moment';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { reporteExc } from '../../actions/auth';
import { uiCloseModalBug, uiCloseReporte } from '../../actions/ui';
import "./modalStyle.css"

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  Modal.setAppElement('#root');
  let initEvent = {
    comment: null,
    type: null,
    job: null,
    seconds: null,
}
    
    
export const EditModalRep = () => {
    const dispatch = useDispatch();

    let usuario = useSelector(state => state.auth);
    let modalOpenReporte = useSelector(state => state.ui.modalOpenReporte);
    const [formValues, setFormValues] = useState( initEvent );

    let {job, seconds, type} = formValues;

    

    const closeModal = () =>{
        dispatch(uiCloseReporte());
    }

    const handleSearch = (e) => {
        e.preventDefault();
        let fecha = moment().tz("Asia/Calcutta").format("MM/DD/YYYY HH:mm:ss");
        if(job==="" || job=== undefined || job=== null || job.length <= 10 || seconds==="" || seconds=== undefined || seconds=== null || type === null ){
 
            Swal.fire({
                icon: 'error',
                title: 'Incorrect format',
                text: 'Please, Use the correct format to report!'
              })
        }else{
            dispatch(reporteExc(fecha,job, usuario.name, seconds, type));
            dispatch(uiCloseReporte());
        }
        
    }
    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }

    return (
        <Modal
            isOpen={modalOpenReporte}
            //onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            className="modal"
            overlayClassName="modal-fondo">
            <div>
                <h1 className="text-center">Report a exceptions</h1>
                <hr />
                <form onSubmit={handleSearch}>
                    <label>Job ID</label>
                    <input 
                        type="text" 
                        placeholder="61c5f307108063001c1ef5e9" 
                        className="form-control" 
                        name="job"
                        autoComplete="off"
                        value={job}
                        onChange={handleInputChange}
                    ></input>
                    <label>Seconds</label>
                    <input 
                        type="text" 
                        placeholder="60" 
                        className="form-control" 
                        name="seconds"
                        autoComplete="off"
                        value={seconds}
                        onChange={handleInputChange}
                    ></input>
                    <label>Job Type</label>
                        <select className="form-control" name="type" onChange={ handleInputChange }>
                            <option selected>Select type</option>
                            <option value="AD"> AD </option>
                            <option value="DR">DR</option>
                            <option value="TM">TM</option>
                            <option value="LIVENESS">LIVENESS</option>
                            <option value="VOICE">VOICE</option>
                        </select>
                    <button type="submit" className="btn mt-2 btn-block btn-outline-primary">Send</button>
                </form>
            </div>
        </Modal>
    )
}
