import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetUser } from '../../selectors/getUsers';
import MaterialTable from "material-table";
import { useState } from "react";
import { userDelete, userUpdate } from '../../actions/auth';
import { NavLink } from 'react-router-dom'
import "./style.css"

export const Usuarios = () => {

    const { role, uid } = useSelector( state => state.auth );

    const dispatch = useDispatch();

    let usuarios = GetUser();

    let userAdmin;
    try {
        userAdmin = usuarios.find(user => user.id === uid);
        if (role === "RH") {
            usuarios=usuarios.filter(users => users.hub === userAdmin.hub && (users.role === "Agente" || users.role === "AgenteDB" ||  users.role === "RH"));
        }else{
            usuarios=usuarios.filter(users => users.hub === userAdmin.hub );
        }
    } catch (error) {
        
    }

    
    const data = usuarios;
    
    
    let variables=[];
    if (role === "Admin") {
        variables  = [
            { title: 'Name', field: 'name' },
            { title: 'Email', field: 'email', initialEditValue: 'initial edit value' },
            { title: 'Password', field: 'password'},
            
            {
              title: 'Role', field: 'role',
              lookup: { "Admin": 'Admin', "Agente": 'Agent', "CoordQA": 'Coord. QA', "CoordCD": 'Coord. CD', "RH": 'RH', "Productivity":'Productivity'},
            },
            {
                title: 'Status', field: 'status',
                lookup: { "Active": 'Active', "Suspended": 'Suspended',  "Vacations": 'Vacations', "Inhability": 'Inhability'},
            },
            {
                title: 'Shift', field: 'shift',
                lookup: {   
                "No turn":"No turn",
                "morning": 'morning', 
                "midday 09:00": 'midday 09:00',
                "midday 10:00": 'midday 10:00',  
                "afternoon": 'afternoon',  
                "night": 'night', 
                "RRHH": "RRHH", 
                "QA morning": "QA morning",
                "QA midday": "QA midday",
                "QA afternoon": "QA afternoon",
                "QA night": "QA night",
            },
            },
            {
                title: 'Hub', field: 'hub',
                lookup: { "IN": 'IN' },
                //lookup: { "MX": 'MX', "IN": 'IN', "BR": "BR" },
            },
            {
                title: 'BreakT', field: 'breakT',
                lookup: {   "No break": "No break",
                            "7:00": '7:00', 
                            "8:00": '8:00',
                            "9:00": '9:00', 
                            "10:00": '10:00', 
                            "11:00": '11:00',
                            "12:00": '12:00', 
                            "13:00": '13:00', 
                            "14:00": '14:00', 
                            "15:00": '15:00',
                            "16:00": '16:00', 
                            "17:00": '17:00',
                            "18:00": '18:00', 
                            "19:00": '19:00',
                            "20:00": '20:00', 
                            "21:00": '21:00',
                            "22:00": '22:00',},
            },
            {
                title: 'DayOff', field: 'dayOff',
                lookup: {   
                "No day off":"No day off",
                "Monday": 'Monday', 
                "Tuesday": 'Tuesday',
                "Wednesday": 'Wednesday',  
                "Thursday": 'Thursday',  
                "Friday": 'Friday', 
                "Saturday": "Saturday", 
                "Sunday": "Sunday",
            
            },
            },
        ];
    }
    if (role === "RH") {
        variables = [
            { title: 'Name', field: 'name' },
            { title: 'Email', field: 'email', initialEditValue: 'initial edit value' },
            { title: 'Password', field: 'password'},
            
            {
              title: 'Role', field: 'role',
              lookup: { "Agente": 'Agent', "RH": 'RH' },
            },
            {
                title: 'Status', field: 'status',
                lookup: { "Active": 'Active', "Suspended": 'Suspended',  "Vacations": 'Vacations', "Inhability": 'Inhability'},
            },
            {
                title: 'Shift', field: 'shift',
                lookup: {   
                    
                            "No turn":"No turn",
                            "morning": 'morning', 
                            "midday 09:00": 'midday 09:00',
                            "midday 10:00": 'midday 10:00',  
                            "afternoon": 'afternoon',  
                            "night": 'night', 
                            "RRHH": "RRHH", 
                            "QA morning": "QA morning",
                            "QA midday": "QA midday",
                            "QA afternoon": "QA afternoon",
                            "QA night": "QA night",
                        },
            },
            {
                title: 'Hub', field: 'hub',
                lookup: { "IN": 'IN' },
                //lookup: { "MX": 'MX', "IN": 'IN', "BR": "BR", "VE":'VE', "AR":'AR', "NG":'NG'},
            },
            {
                title: 'BreakT', field: 'breakT',
                lookup: {   "No break": "No break",
                            "7:00": '7:00', 
                            "8:00": '8:00',
                            "9:00": '9:00', 
                            "10:00": '10:00', 
                            "11:00": '11:00',
                            "12:00": '12:00', 
                            "13:00": '13:00', 
                            "14:00": '14:00', 
                            "15:00": '15:00',
                            "16:00": '16:00', 
                            "17:00": '17:00',
                            "18:00": '18:00', 
                            "19:00": '19:00',
                            "20:00": '20:00', 
                            "21:00": '21:00',
                            "22:00": '22:00',},
            },
            {
                title: 'DayOff', field: 'dayOff',
                lookup: {   
                    
                "No day off":"No day off",
                "Monday": 'Monday', 
                "Tuesday": 'Tuesday',
                "Wednesday": 'Wednesday',  
                "Thursday": 'Thursday',  
                "Friday": 'Friday', 
                "Saturday": "Saturday", 
                "Sunday": "Sunday",
            
            },
            },
        ];
    }
    let [columns] = useState(variables);
    
    console.log("|"+role+"|");

    const changeStatus = (agente, usuario, status) => {
        const request = new XMLHttpRequest();
        request.open("POST", "https://discord.com/api/webhooks/958800679969906698/erlOn6f5FZSAOr9C228p5J6oI85FK_m1qBc_uWlRtb24gWF31Digx3Cgj1tdjYUPP--H");
        request.setRequestHeader('Content-type', 'application/json');
        const params = {
            username: "Cambios de Status RRHH",
            avatar_url: "https://i.ytimg.com/vi/JQas1YbGBXo/hqdefault.jpg",
            content: ("*Agente RRHH:* "+agente+`\n*Usuario:* `+usuario+`\n*Status:* `+status+`\n*----------------* `)
        }
        request.send(JSON.stringify(params));
        mensaje("Enviando mensaje")

        
        
    }

    const mensaje = (msn) =>{
        console.log(msn)

    }

    return (
        <div>
            <hr />
            <div className="container-fliud center text-center">
                {(role==="Admin" || role === "RH" )&&
                    <div>
                        <h1 className="text-center" style={{ color: '#ffffff' }}>USERS</h1>
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-success">
                                <NavLink to={`../../users/new`} className="stretched-link"style={{ color: 'white' }}><i class="fas fa-user-plus"></i> New user</NavLink>
                            </button>
                        </div>
                    </div>
                }
                {(role==="Agente" || role==="AgenteDB" || role==="CoordQA" || role==="CoordDB" || role==="CoordCD" || role=== "PD Admin")&&
                    <div>
                        <h1 className="text-center" style={{ color: '#ffffff' }}>Access Denied</h1>
                    </div>
                }
                <hr />
            </div>
            {(role==="Admin"|| role === "RH")&&
                    <div> 
                    <MaterialTable
                        className="usuarios-table"
                        options={{
                            rowStyle:{backgroundColor:'#f2f2f2'},
                            headerStyle:{backgroundColor:'#f2f2f2'},
                            actionsCellStyle:{backgroundColor:'#f2f2f2'},
                            style:{backgroundColor:'#f2f2f2'}
                            }}
                        style={{backgroundColor:'#f2f2f2'}}
                        title=""
                        columns={columns}
                        data={data}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                //console.log(dataUpdate);
                                //console.log(newData);
                                const { name, email, password, role, id, status, shift, hub, breakT, photo, dayOff} = newData;
                                dispatch(userUpdate( name, email, password, role, id, status, shift, hub, breakT, photo, dayOff));
                                if (newData.status !== oldData.status){
                                    changeStatus(userAdmin.name,name, status);       

                                }
                                
                                //resolve();
                                //history.go(0)
                                }, 1000)
                                
                            }),
                            onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                const index = oldData.tableData.id;
                                const dataUpdate = data[index];
                                //console.log(dataUpdate);
                                //console.log(dataUpdate.id);
                                dispatch(userDelete( dataUpdate.id));
                                resolve()
                                //history.go(0)
                                }, 1000)
                            }),
                        }}
                    />
                </div>
                }
            
        </div>
        
    )
}
