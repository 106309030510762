import { types } from '../types/types';



export const uiOpenModalDoc = () => ({ type: types.uiOpenModalDoc });
export const uiCloseModalDoc = () => ({ type: types.uiCloseModalDoc });
export const uiOpenModalBug = () => ({ type: types.uiOpenModalBug });
export const uiCloseModalBug = () => ({ type: types.uiCloseModalBug });

export const uiOpenReporte = () => ({ type: types.uiOpenReporte });
export const uiCloseReporte = () => ({ type: types.uiCloseReporte });

export const uiOpenReporteT = () => ({ type: types.uiOpenReporteT });
export const uiCloseReporteT = () => ({ type: types.uiCloseReporteT });

export const uiOpenModalSr = () => ({ type: types.uiOpenModalSr});
export const uiCloseModalSr = () => ({ type: types.uiCloseModalSr});


//add by Mayra


export const uiOpenModalErizo = () => ({ type: types.uiOpenModalErizo });
export const uiCloseModalErizo = () => ({ type: types.uiCloseModalErizo });
export const uiOpenModalMemes= () => ({ type: types.uiOpenModalMemes });
export const uiCloseModalMemes= () => ({ type: types.uiCloseModalMemes });
export const uiOpenModalSpecialRules= () => ({ type: types.uiOpenModalSpecialRules});
export const uiCloseModalSpecialRules= () => ({ type: types.uiCloseModalSpecialRules});
export const uiOpenModalObsoleteRules= () => ({ type: types.uiOpenModalObsoleteRules});
export const uiCloseModalObsoleteRules= () => ({ type: types.uiCloseModalObsoleteRules});

//Add & Modified by Yaelo
export const uiOpenModalVideos = () => ({ type: types.uiOpenModalVideos });
export const uiCloseModalVideos = () => ({ type: types.uiCloseModalVideos });

export const uiOpenModalBonos = () => ({ type: types.uiOpenModalBonos });
export const uiCloseModalBonos = () => ({ type: types.uiCloseModalBonos });