import React from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from "../../actions/countries";
import MaterialTable from "material-table";
import { useState , useEffect } from "react";
import { getAllAlerts, updateCheck, updateCheckA, newCheckA } from '../../actions/checker';
import "./style.css"
import moment from 'moment';
import { CSVLink } from "react-csv";
import Swal from "sweetalert2";
import Loader from '../Loader/Loader';
import Select from 'react-select'
const baseUrl = process.env.REACT_APP_API_URL;


export const CheckAlerts = () => {

    const { role, uid } = useSelector( state => state.auth );
    console.log("role: ", role, " uid: "+uid);

    const dispatch = useDispatch();
    //----------------------------------------------
    let date = moment().tz("Asia/Calcutta").format("YYYY-MM-DD");
    const [dateFormat, setDateFormat]= useState(date);
    const [dateFormat2, setDateFormat2]= useState(date);
    const [shiftState, setshiftState]= useState("morning");
    const [arr, setarr]= useState([]);
    const [atendidos, setAtendidos]= useState([]);
    const [btn, setBtn]= useState(false);
    // const [hub, setHub]= useState(["MX"]);
    //const [checkA, setCheckA]= useState([]);

    const [loader, setLoader]= useState(true);//use state loader

    // const optionsHub = [
    //     { value: 'MX', label: 'MX' },
    //     { value: 'AR', label: 'AR' },
    //     { value: 'BR', label: 'BR' },
    //     { value: 'IN', label: 'IN' },
    //     { value: 'NG', label: 'NG' },
    //     { value: 'VE', label: 'VE' },
    //   ]

    useEffect(async() => {
        console.log("-------- execute use effect --------")
        //console.log(hub);
        setLoader(true);
        const token = localStorage.getItem('token') || '';
        console.log(token);
        const dateArray=dateFormat.split('-');
        const dateArray2=dateFormat2.split('-');
        const dateToQuery=dateArray[1]+"-"+dateArray[2]+"-"+dateArray[0]
        const dateToQuery2=dateArray2[1]+"-"+dateArray2[2]+"-"+dateArray2[0]
        // const stringHub= hub.join(",");
        // if(stringHub===""){
        //     alert("Seleccione un hub");
        //     setLoader(false);
        // }
        await axios.get(`${baseUrl}/checkA/all/${dateToQuery}/${dateToQuery2}/${shiftState}`, { headers: {"x-token" : `${token}`} })
                    .then(async res => 
                        {
                            console.log('profile is:', res.data);
                            await loopchecka(res.data.result);
                            setLoader(false);
                            // await loopcheckaten(res.data.result);
                        })
                    .catch(error => 
                            console.log(error
                        ))
        await axios.get(`${baseUrl}/anomalies/${dateToQuery}/${dateToQuery2}`, { headers: {"x-token" : `${token}`} })
        .then(async res => 
            {
                console.log('anomalies is:', res.data);
                // await loopchecka(res.data.result);
                // setLoader(false);
                await loopcheckaten(res.data.result);
            })
        .catch(error => 
                console.log(error
            ))

        //await dispatch(getAllAlerts(dateFormat , dateFormat2, shiftState));
        //const timer = setTimeout(() => { //timer--------------->
         //change the useState loader
        //}, 5000); //timer--------------->
        
    }, [btn])
    //dateFormat , dateFormat2, shiftState

    useEffect(async() => {
        console.log("//////////    execute second use effect  /////////")
        await dispatch(getUsers());
        //const timer = setTimeout(() => { //timer--------------->
        //setLoader(false) //change the useState loader
        //}, 5000); //timer--------------->
        
    }, [])

    const updateAnomalies= async (idCh, anom, com)=>{
        anom=(anom===undefined)?"":anom;
        com=(com===undefined)?"":com;
        const token = localStorage.getItem('token') || '';
        var postData = {idChecker: idCh,anomalia: anom,comment:com};
        let axiosConfig = {headers: {"x-token" : `${token}`}};
        await axios.post(`${baseUrl}/anomalies/${idCh}`,postData,axiosConfig)
                   .then(async res => 
                        {
                            console.log(res);
                            //return res;
                            
                        })
                   .catch(error => {
                            console.log(error)
                            //return error;
                   })
    }
  
    //let checksA = useSelector(state => state.check.checkA);
    //let checks = useSelector(state => state.check.check);

    const onChangeDate = (e) =>{
        e.preventDefault();
        const dateArray=e.target.value.split("-");
        const currentDate = moment().tz("Asia/Calcutta");
        const queryDate= moment(dateArray[0]+""+dateArray[1]+""+(dateArray[2]));
        console.log("fecha construida: "+ queryDate)
        console.log("fecha actual: "+currentDate+" fecha consulta: "+queryDate); 
        if(queryDate>currentDate){
            Swal.fire('Error',"Unable to query a date in the future", 'error');
            return;
        }else{
            setDateFormat(e.target.value);
        }
    }

    const onChangeDate2 = (e) =>{
        e.preventDefault();
        const dateArray=e.target.value.split("-");;
        const currentDate = moment().tz("Asia/Calcutta");
        const queryDate= moment(dateArray[0]+""+dateArray[1]+""+(dateArray[2]));
        console.log("fecha construida: "+ queryDate)
        console.log("fecha actual: "+currentDate+" fecha consulta: "+queryDate); 
        if(queryDate>currentDate){
            Swal.fire('Error',"Unable to query a date in the future", 'error');
            return;
        }else{
            setDateFormat2(e.target.value);
        }
    }

    const onChangeShift = (e) =>{
        e.preventDefault();
        const electedShift=e.target.value;
        console.log(`El turno elegido es: ${electedShift}`) 
            setshiftState(e.target.value);
        
    }
    
    const onChangeBtn=(e)=>{
        e.preventDefault();
        setBtn((btn===true)?false:true);
    }
    
    // const onChangeHub = (e) =>{
    //     const hubArray=[]
    //     e.map(item=>{
    //         hubArray.push(item.value)
    //     })
    //     setHub(hubArray);
    // }


    //------------------------------------------------------------------------------

   
    //let arrayHrs =[];
    //let arrayAtendidos =[];
    /*let userAdmin;
     try {
         userAdmin = usuarios.find(user => user.id === uid);
     } catch (error) {
         console.log("Hi");
     }
     console.log("useradmin"+JSON.stringify(userAdmin));
    */
    

    //Alertas sin atender
    const loopchecka= async(checker)=>{
        console.log(checker);
        const arrAux=[];
        await checker.map(checkA => {
        // if (usuarios !== undefined) {
        //     console.log("usuario es diferente de undefinido")
        //     for (let i = 0; i < usuarios.length; i++) {
        //         if (checkA.uid === usuarios[i].id &&  usuarios[i].hub === userAdmin.hub) {
        //             checks.map(check => {
        //                 if (check.id === checkA.idChecker && checkA.reviso==="") {
                            const obj = {
                                // "id": checkA.id , 
                                // "idchecker": check.id , 
                                // "name": usuarios[i].name, 
                                // "shift": usuarios[i].shift, 
                                // "fecha": checkA.fecha, 
                                // "checkin":check.checkin,
                                // "checkout":check.checkout,
                                // "break":check.breakC,
                                // "return":check.returnC,
                                // "anomalia": checkA.anomalia, 
                                // "accion": checkA.accion,
                                // "reviso": checkA.reviso,
                                // "total": check.total,
                                "id": checkA._id , 
                                "idchecker": checkA._id_checker , 
                                "name": checkA.name, 
                                "shift": checkA.shift, 
                                "fecha": checkA.fechaChecker, 
                                "checkin":checkA.checkin,
                                "checkout":checkA.checkout,
                                "breakC":checkA.breakC,
                                "returnC":checkA.returnC,
                                "anomalia": checkA.anomalia, 
                                "accion": checkA.accion,
                                "reviso": checkA.reviso,
                                "total": checkA.total,
                                "totalB": checkA.totalB,
                            };
                            arrAux.push(obj); 
                            //console.log("object: ",obj)
        //                 }
        //              }) 
        //         }
        //     } 
        // }
    })  
    setarr(arrAux);
}
    //Alertas atendidas 
    const loopcheckaten= async(checker)=>{
        console.log(checker);
        const arrAux=[];
    checker.map(checkA => {
        // if (usuarios !== undefined) {
        //     for (let i = 0; i < usuarios.length; i++) {
                // if (checkA.uid === usuarios[i].id &&  usuarios[i].hub === userAdmin.hub) {
                    // checks.map(check => {
                    //     if (check.id === checkA.idChecker && checkA.reviso !=="") {
                            
                            const obj = {
                                // "name": usuarios[i].name, 
                                // "shift": usuarios[i].shift, 
                                // "fecha": checkA.fecha, 
                                // "checkin":check.checkin,
                                // "checkout":check.checkout,
                                // "break":check.breakC,
                                // "return":check.returnC,
                                // "anomalia": checkA.anomalia, 
                                // "accion": checkA.accion,
                                // "reviso": checkA.reviso,
                                // "total": check.total,
                                "Name": checkA.name, 
                                "Shift": checkA.shift, 
                                "Date": checkA.fechaChecker, 
                                "Checkin_corrected":checkA.checkin,
                                "Checkin_before":checkA.anomalieCheckin,
                                "Checkout_corrected":checkA.checkout,
                                "Checkout_before":checkA.anomalieCheckout,
                                "Break_corrected":checkA.breakC,
                                "Break_before":checkA.anomalieBreak,
                                "Return_corrected":checkA.returnC,
                                "Return_before":checkA.anomalieReturn,
                                "Anomalie": checkA.anomalia, 
                                "Accion": checkA.anomalieComment,
                                "Reviewed_by": checkA.anomalieReviso,
                                "Total": checkA.total,
                            };
                           arrAux.push(obj); 
                    //     }
                    //  }) 
                // }
        //     } 
        // }
    }) 
    setAtendidos(arrAux);
}
    //const data = arrayHrs;
    //console.log(checksA);

    const [columns] = useState([
        { title: 'Worker', field: 'name'},
        { title: 'Shift', field: 'shift'},
        { title: 'Date', field: 'fecha'},
        { title: 'CheckIn', field: 'checkin'},
        { title: 'CheckOut', field: 'checkout'},
        { title: 'Break', field: 'breakC'},
        { title: 'Return', field: 'returnC'},
        { title: 'Anomaly', field: 'anomalia'},
        { title: 'Total Hrs', field: 'total'},
        { title: 'Comment', field: 'accion'},
      ]);
    
    //console.log("|"+role+"|");

    
    return (
        <div>
            <hr />
            <div className="container-fliud center text-center">
                {(role==="Admin"|| role==="RH" )&&
                <>
                    <div>
                        <h1 className="text-center" style={{ color: '#ffffff' }}>CHECKER ALERTS</h1>
                        <hr />

                    {/*--------------------------------- add Mayra------------------------- ---    */}
                    </div>
                    <div className='row col-lg-12 mb-5'> 
                    <div  className='row col-lg-6 mx-auto'>
                        <div className='row col-lg-4 mx-auto' >
                            <label className='col-lg-12 text-white text-left'>From</label>
                            <input type="date" className ="form-control" name='FechaInicial' value={dateFormat} max={date} onChange={onChangeDate}>
                                
                            </input>
                        </div>
                        <div className='row col-lg-4 mx-auto'>
                        <label className='col-lg-12 text-white text-left'>To</label>
                            <input type="date" className ="form-control" name='FechaFinal' value={dateFormat2} max={date} onChange={onChangeDate2}>
                                
                            </input>

                        </div>
                    </div>
                    <div className='row col-lg-2 mx-auto' >
                            <label className='col-lg-12 text-white text-left'>Shift</label>
                            <select className="form-control " name="shift" value={shiftState} onChange={ onChangeShift } >
                                        {/* <option selected>Select Shift</option> */}
                                        <option selected value="morning"> morning </option>
                                        <option value="afternoon"> afternoon </option>
                                        <option value="night"> night </option>
                                        <option value="midday 10:00"> midday 10:00</option>
                                        <option value="midday 09:00"> midday 09:00</option>
                                        <option value="QA morning"> QA morning</option>
                                        <option value="QA midday"> QA midday</option>
                                        <option value="QA afternoon"> QA afternoon</option>
                                        <option value="QA night"> QA night</option>
                                        <option value="RRHH">RRHH</option>
                                        <option value="No turn"> No Turn</option>
                                </select>
                        </div>
                        <div className='row col-lg-2 mx-auto' >
                            <label className='col-lg-12 text-white text-left'></label>
                            <button className="btn btn-outline-info" onClick={onChangeBtn}><i className="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                        {/* <div className='row col-lg-3 mx-auto' >
                            <label className='col-lg-12 text-white text-left'>Shift</label>
                             <select className="form-control " name="hub" multiple={true} value={hub} onChange={ onChangeHub } >
                                        <option selected>Select Shift</option> 
                                        <option selected value="MX"> MX </option>
                                        <option value="AR"> AR </option>
                                        <option value="BR"> BR </option>
                                        <option value="NG"> NG</option>
                                        <option value="VE"> VE</option>
                                        <option value="IN"> IN</option>
                                </select> 
                                <Select
                                    isMulti 
                                    options={optionsHub}
                                    onChange={ onChangeHub } 
                                />
                        </div> */}
                    <div className=' col-lg-2 row'>
                    
                        <label className='col-lg-12 text-white text-left'></label>
                        <button type="button" class="btn btn-success float-right">
                            <i class="fas fa-file-csv"></i> 
                            <CSVLink 
                                style={{ color: '#ffffff' }} 
                                filename={`Checker-${dateFormat}-to-${dateFormat2}.csv`} 
                                data={atendidos}> Download
                            </CSVLink>
                        </button>
                    </div>
                    </div>
                    </>
        // -----------------------------------------------------------------------------------------------------
                }
                {(role==="Agente" || role==="AgenteDB"  || role==="CoordQA" || role==="CoordDB" || role==="CoordCD" || role==="PD Admin")&&
                    <div>
                        <h1 className="text-center" style={{ color: '#ffffff' }}>Access Denied</h1>
                    </div>
                }
                
            </div>
            
               
            {//---------------------------------------- start loader -------------------------------------
            
            (loader)&&
                <div className='col-lg-12 row'>
                    <div class="spinner-border text-white col-lg-4 mx-auto text-center" role="status" key={"status"}>
                        
                        <Loader/>
                        <h3>Loading...</h3>
                    </div>
                </div>
            //---------------------------------------- loader end ----------------------------------------
            } 
            {((role==="Admin" || role==="RH")&&loader===false)&&
                    <div> 
                    <MaterialTable
                    
                        className="usuarios-table"
                        options={{
                            rowStyle:{backgroundColor:'#f2f2f2'},
                            headerStyle:{backgroundColor:'#f2f2f2'},
                            actionsCellStyle:{backgroundColor:'#f2f2f2'},
                            style:{backgroundColor:'#f2f2f2'}
                            }}
                        style={{backgroundColor:'#f2f2f2', zIndex: '0' }}
                        title=""
                        columns={columns}
                        data={arr}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout( async () => {
                                console.log(newData);
                                const {id, idchecker, total, anomalia,  accion,fecha, checkin, checkout, returnC, breakC} = newData;
                                let registroAlerta = arr.find(checks=> checks.id === id && checks.fecha===fecha);

                                //let registroCheck = arr.find(checks=> checks.id === idchecker);
                                console.log("registroAlerta",registroAlerta);
                                //onsole.log(registroCheck);
                                //console.log("id: ",registroAlerta._id,"\nuid: " ,registroAlerta.uid,"\nidChecker: ", registroAlerta.idchecker,"\nfecha: ", registroAlerta.fechaAlert,"\nanomalia",  anomalia,"\naccion",  accion, "\nname", userAdmin.name)
                                console.log(`id: ${registroAlerta.idchecker}, uid: ${registroAlerta.id}, fecha: ${registroAlerta.fecha}, checkin: ${checkin}, checkout: ${checkout}, break: ${breakC}, return: ${returnC}, totalB: ${registroAlerta.totalB}, total: ${total}`)
                                
                                //dispatch(updateCheckA(id, registroAlerta.uid, registroAlerta.idchecker, registroAlerta.fecha,  anomalia,  accion, userAdmin.name));
                                await updateAnomalies(registroAlerta.idchecker,anomalia, accion);
                                dispatch(updateCheck( registroAlerta.idchecker, registroAlerta.id, registroAlerta.fecha, checkin, checkout, breakC, returnC, registroAlerta.totalB, total ));
                                resolve();
                                return;
                                //history.go(0)
                                }, 1000)
                                
                            }),
                            onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                const index = oldData.tableData.id;
                                const dataUpdate = arr[index];
                                //console.log(dataUpdate);
                                //console.log(dataUpdate.id);
                                //dispatch(userDelete( dataUpdate.id));
                                resolve()
                                //history.go(0)
                                }, 1000)
                            }),
                        }}
                    />
                </div>
                }
            
        </div>
        
    )
}
