import moment from 'moment';
import React, { useEffect, useState }from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm';
//import { GetCheck } from '../../selectors/getCheck';
import Swal from "sweetalert2";
import { getAllChecK } from '../../actions/checker';
import { GetUser } from '../../selectors/getUsers';
import Loader from '../Loader/Loader' //import loader


export const Agents = () => {

    const { role, uid } = useSelector( state => state.auth );
    const dispatch = useDispatch();
    
    //console.log("la fecha parseada es: ",dateFormat);
    //GetCheck();

     
    //add Mayra
    //----------------------------------------------
    let date = moment().tz("Asia/Calcutta").format("YYYY-MM-DD");
    const [dateFormat, setDateFormat]= useState(date);
    const [loader, setLoader]= useState(true);//use state loader

    useEffect(async() => {
        console.log("********************* execute use effect *******************")
        setLoader(true); //change the useState loader
        await dispatch(getAllChecK(dateFormat));
        //const timer = setTimeout(() => { //timer--------------->
        setLoader(false) //change the useState loader
       // }, 5000); //timer--------------->
        
    }, [dateFormat])
  //----------------------------------------------

    
    let fecha = moment().tz("Asia/Calcutta").format("MM-DD-YYYY");
    
    
    //console.log(fecha);
    const checks = useSelector(state => state.check.check);
    //console.log("check: " + JSON.stringify(checks))// add Mayra
    //let checksFilter = checks.filter(checks=> checks.fecha === fecha);
    let checksFilter = checks;
    //console.log(checksFilter);
    
    
    const [formValues2, handleInputChange2] = useForm();
    const {shift} = formValues2;

    const handleSearch2 = (e) =>{
        e.preventDefault();
    }

    const onChangeDate = (e) =>{
        e.preventDefault();
        const dateArray=e.target.value.split("-");
        // const currentDate= new Date();
        // const queryDate = new Date(dateArray[0]+","+dateArray[1]+","+(dateArray[2])+",1,1,1");
        //queryDate= queryDate.setDate(queryDate.getDate()+1);
        const currentDate = moment().tz("Asia/Calcutta");
        const queryDate= moment(dateArray[0]+""+dateArray[1]+""+(dateArray[2]));
        // const queryDate = new Date(dateArray[0]+","+dateArray[1]+","+(dateArray[2])+",1,1,1");
        console.log("fecha construida: "+ queryDate)
        console.log("fecha actual: "+currentDate+" fecha consulta: "+queryDate); 
        //if(queryDate>currentDate){
            //Swal.fire('Error',"Unable to query a date in the future", 'error');
            //return;
        //}else{
            setDateFormat(e.target.value);
        //}
    }

    let usuarios = GetUser();
    let userAdmin;
    let agente;
    let agentes = [];
    try {
        userAdmin = usuarios.find(user => user.id === uid);
        if (shift===undefined) {
                usuarios = usuarios.filter(users=> users.shift === "morning" );
        }else{
            usuarios = usuarios.filter(users=> users.shift === shift );
        }
        for (let i = 0; i < usuarios.length; i++) {
            agente = checksFilter.find(checks => checks.uid === usuarios[i].id);
            if (agente) {
                //console.log(agente);
                agentes.push({name:usuarios[i].name, dayOff:usuarios[i].dayOff, breakHour:usuarios[i].breakT, checkin:agente.checkin, checkout:agente.checkout, break:agente.breakC, return:agente.returnC });
            }else{
                agentes.push({name:usuarios[i].name, dayOff:usuarios[i].dayOff, breakHour:usuarios[i].breakT, checkin:"", checkout:"", break:"", return:"" });
            }
        }
        //console.log(agentes);
    } catch (error) {
        //console.log("Hi");
    }
    
    return (
        <div>
            <hr />
            <div className="container-fliud center text-center" key={"title"}>
                {(role==="Admin"|| role==="RH"  || role === "Productivity")&&
                    <div key={"agents"}>
                        <h1 className="text-center" style={{ color: '#ffffff' }}>AGENTS</h1>
                    </div>
                }
                {(role==="Agente" || role==="AgenteDB" || role==="CoordQA" || role==="CoordDB" || role==="CoordCD" || role==="PD Admin")&&
                    <div key={"access"}>
                        <h1 className="text-center" style={{ color: '#ffffff' }}>Access Denied</h1>
                    </div>
                }
                <hr />
            </div>
            
            <div className="row text-left mb-4">
                <div className="col-lg-4 row mx-auto">
                    <div className='col-lg-12 ' key={"date"}>
                        <p  style={{ color: '#ffffff' }}>Date</p>
                    </div>
                    <div className='col-lg-12 ' key={"agentsInput"}>
                        <input className="form-control" type = "date" value={dateFormat} onChange={onChangeDate}></input>
                    </div>
                </div>
                
                <div className="col-lg-4 row mx-auto">
                    <div className='col-lg-12' key={"shift"}>
                        <p  style={{ color: '#ffffff' }}>Select a shift</p>
                    </div>
                    <div className='col-lg-12' key={"select"}>
                        <form onSubmit={handleSearch2}>
                            <select className="form-control" name="shift" onChange={handleInputChange2}>
                                <option key={"morning"}     value="morning">morning</option>
                                <option key={"midday09-00"} value="midday 09:00">midday 09:00</option>
                                <option key={"midday10-00"} value="midday 10:00">midday 10:00</option>
                                <option key={"affternoon"}  value="afternoon">afternoon</option>
                                <option key={"night"}       value="night">night</option>
                                <option key={"RRHH"}        value="RRHH">RRHH</option>
                                <option key={"QAMorning"}   value="QA morning">QA morning</option>
                                <option key={"QAMidday"}    value="QA midday">QA midday</option>
                                <option key={"QAAfternoon"} value="QA afternoon">QA afternoon</option>
                                <option key={"QANight"}     value="QA night">QA night</option>
                                <option key={"No turn"}        value="No turn"> No turn</option>
                            </select>
                        </form>
                    </div>
                </div>
                
            </div>
            
            {//---------------------------------------- start loader -------------------------------------
            
            (loader)&&
                <div className='col-lg-12 row'>
                    <div class="spinner-border text-white col-lg-4 mx-auto text-center" role="status" key={"status"}>
                        
                        <Loader/>
                        <h3>Loading...</h3>
                    </div>
                </div>
            //---------------------------------------- loader end ----------------------------------------
            }
            
            {((role==="Admin" || role==="RH" || role==="Productivity")&&loader===false)&&
                <table className="table table-hover table-dark ">
                    <thead>
                    <tr>
                        <th key={"agent"} scope="col">Agent</th>
                        <th key={"dayoff"} scope="col">Day Off</th>
                        <th key={"breakhour"} scope="col">Break Hour</th>
                        <th key={"checkin"} scope="col">CheckIn</th>
                        <th key={"checkout"}  scope="col">CheckOut</th>
                        <th key={"break"} scope="col">Break</th>
                        <th key={"return"} scope="col">Return</th>
                        <th key={"status"} scope="col">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        agentes.map(check => {
                            return ( 
                                <tr key={check.uid}>
                                    <td key={check.uid+"1"}>{check.name}</td>
                                    <td key={check.uid+"2"}>{check.dayOff}</td>
                                    <td key={check.uid+"3"}>{check.breakHour}</td>
                                    <td key={check.uid+"4"}>{check.checkin}</td>
                                    <td key={check.uid+"5"}>{check.checkout}</td>
                                    <td key={check.uid+"6"}>{check.break}</td>
                                    <td key={check.uid+"7"}>{check.return}</td>
                                    {(check.checkin !== "" && check.break ==="" && check.checkout ==="")&&
                                       <td style={{ color: '#1E8449' }}>Online</td> 
                                    }
                                    {(check.checkin !== "" && check.checkout !== "")&&
                                       <td style={{ color: '#A93226' }}>Offline</td> 
                                    }
                                    {(check.break !== "" && check.return === "" && check.checkout ==="")&&
                                       <td style={{ color: '#F1C40F' }}>Break</td> 
                                    }
                                    {(check.return !== "" && check.checkout === "")&&
                                       <td style={{ color: '#1E8449' }}>Online</td> 
                                    }
                                    {(check.checkin === "" && check.checkout === "" && check.break === "" && check.return === "")&&
                                       <td style={{ color: '#A93226' }}>No activity today</td> 
                                    }
                                </tr>
                            )
                        })
                        
                    }
                    </tbody>
                </table>
            }
        </div>
    )
}
