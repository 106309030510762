import React, { useState } from 'react';
import Clock from 'react-live-clock';
import { useDispatch, useSelector } from 'react-redux';
import "./styles.css";
import moment from 'moment';
import Swal from "sweetalert2";
import { GetCheck, GetCheckA, GetCheckE } from '../../selectors/getCheck';
import { newCheck, newCheckA, newCheckE, updateCheck, updateCheckA, updateCheckE } from '../../actions/checker';
import { GetUser } from '../../selectors/getUsers';
import DatePicker from 'react-date-picker';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

let initEvent = {
    check: '', 
}
export const Checker = () =>{
    const dispatch = useDispatch();

    let support = false;

    GetCheck();
    GetCheckE();
    GetCheckA();
    
        
    const checks = useSelector(state => state.check.check);
    const checksE = useSelector(state => state.check.checkE);
    let checksA = useSelector(state => state.check.checkA);
    let user = useSelector( state => state.auth );
    //console.log(user);

    let usuarios = GetUser();
    
    if(usuarios){
        usuarios = usuarios.filter(checks=> checks.id === user.uid);
    }
    console.log(usuarios);

    let myChecks = checks.filter(checks=> checks.uid === user.uid);
    let myChecksE = checksE.filter(checksE=> checksE.uid === user.uid);
    let myChecksA = checksA.filter(checksA=> checksA.uid === user.uid);
    let hoy;
    let hora;
    let limite;
    if (usuarios !== undefined) {
        switch(usuarios[0].hub) {
            /*case "MX":
                hoy = moment().tz("America/Mexico_City").format("MM-DD-YYYY");
                hora = moment().tz("America/Mexico_City").format("HH:mm:ss");
                limite = moment("23:30:00","HH:mm:ss").tz("America/Mexico_City");
                console.log(`El dia de hoy es ${hoy} La hora es: ${hora}`)
                break;*/
            case "IN":
                hoy = moment().tz("Asia/Calcutta").format("MM-DD-YYYY");
                hora = moment().tz("Asia/Calcutta").format("HH:mm:ss");
                limite = moment("23:30:00","HH:mm:ss").tz("Asia/Calcutta");
                break;
            /*case "BR":
                hoy = moment().tz("America/Sao_Paulo").format("MM-DD-YYYY");
                hora = moment().tz("America/Sao_Paulo").format("HH:mm:ss");
                limite = moment("23:30:00","HH:mm:ss").tz("America/Sao_Paulo");
                break;*/
            default:
                hoy = moment().tz("Asia/Calcutta").format("MM-DD-YYYY");
                hora = moment().tz("Asia/Calcutta").format("HH:mm:ss");
                limite = moment("23:30:00","HH:mm:ss").tz("Asia/Calcutta");
                break;
        }
    }

    let estadoExtra = myChecksE.find(checks=> checks.fecha === hoy && checks.total === 0);
    
    let estado = myChecks.find(checks=> checks.fecha === hoy);
    console.log(hora);
    console.log(limite);
    const lim = moment(hora,"HH:mm:ss").diff(moment(limite, "HH:mm:ss"));
    console.log(lim);
    if(estado){
        if(estado.checkout !== "" && lim >= 0){
            let tm =moment(hoy).add(1, 'day').format("MM-DD-YYYY");  
            estado = myChecks.find(checks=> checks.fecha === tm);
        }else{
            console.log("En turno");
        }
    }
    const [formValues, setFormValues] = useState( initEvent );
    let [from, onChange] = useState(new Date());

    let [to, onChange2] = useState(new Date());


    let checksFilter = myChecks.filter(checks=>  moment(from, "MM-DD-YYYY").diff(moment(checks.fecha, "MM-DD-YYYY")) <= 0 && moment(to, "MM-DD-YYYY").diff(moment(checks.fecha, "MM-DD-YYYY")) >= 0);
    let checksFilterE = myChecksE.filter(checks=>  moment(from, "MM-DD-YYYY").diff(moment(checks.fecha, "MM-DD-YYYY")) <= 0 && moment(to, "MM-DD-YYYY").diff(moment(checks.fecha, "MM-DD-YYYY")) >= 0);

    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();

        let time;
        let fecha;
        let fechaM;
        let hora;
        let hora2;
        let dia;


        /*if (usuarios[0].hub === "MX") {
            time = moment().tz("America/Mexico_City").format("MM-DD-YYYY HH:mm:ss");
            fecha = moment().tz("America/Mexico_City").format("MM-DD-YYYY");
            hora = moment().tz("America/Mexico_City").format("HH:mm:ss");
            hora2 = moment().tz("America/Mexico_City").format("HH");
            dia = moment().add(1, 'day').tz("America/Mexico_City").format("MM-DD-YYYY"); 
            fechaM =moment(fecha).add(1, 'day').tz("America/Mexico_City").format("MM-DD-YYYY");  
        }*/
        if (usuarios[0].hub === "IN") {
            time = moment().tz("Asia/Calcutta").format("MM-DD-YYYY HH:mm:ss");
            fecha = moment().tz("Asia/Calcutta").format("MM-DD-YYYY");
            hora = moment().tz("Asia/Calcutta").format("HH:mm:ss");
            hora2 = moment().tz("Asia/Calcutta").format("HH");
            dia = moment().add(1, 'day').tz("Asia/Calcutta").format("MM-DD-YYYY"); 
        }
        /*if (usuarios[0].hub === "BR") {
            time = moment().tz("America/Sao_Paulo").format("MM-DD-YYYY HH:mm:ss");
            fecha = moment().tz("America/Sao_Paulo").format("MM-DD-YYYY");
            hora = moment().tz("America/Sao_Paulo").format("HH:mm:ss");
            hora2 = moment().tz("America/Sao_Paulo").format("HH");
            dia = moment().add(1, 'day').tz("America/Sao_Paulo").format("MM-DD-YYYY"); 
        }*/
        
        if(formValues.check === "Check-In"){
            let repetido = myChecks.find(checks=> checks.fecha === fecha);
            //console.log(repetido);
            if(repetido && hora2 !== "23"){
                //Ya ha hecho check in
                
                Swal.fire('No!','You have already checked-in at '+repetido.checkin,'error');
            }else{
                //No ha hecho check in
                if(hora2 === "23"){
                    let repetidoM = myChecks.find(checks=> checks.fecha === fechaM);
                    if(repetidoM){
                        Swal.fire('No!','You have already checked-in at '+repetido.checkin,'error');
                    }else{
                        Swal.fire('Welcome!','You checkIn at '+time,'success');
                        console.log("Aca");
                        let limite
                        if (usuarios[0].shift === "midday 10:00") {
                            limite = "10:00:00";
                        }
                        if (usuarios[0].shift === "midday 09:00" || usuarios[0].shift === "QA midday") {
                            limite = "09:00:00";
                        }
                        if (usuarios[0].shift === "morning" || usuarios[0].shift === "QA morning") {
                            limite = "06:00:00";
                        }
                        if (usuarios[0].shift === "afternoon" || usuarios[0].shift === "QA afternoon") {
                            limite = "15:00:00";
                        }
                        if (usuarios[0].shift === "night" || usuarios[0].shift === "QA night") {
                            limite = "00:00:00";
                        }
                        let limiteInf = moment(limite).add(-10, 'minutes').format("HH:mm:ss"); 
                        let limiteSup = moment(limite).add(10, 'minutes').format("HH:mm:ss");
                        console.log("aqui es el limite superio: " + limiteInf);
                        console.log("aqui es el limite inferior: " + limiteSup);
                        limiteInf= moment(hora,"HH:mm:ss").diff(moment(limiteInf, "HH:mm:ss")); //si es MAYOR a 0 esta dentro de tiempo
                        limiteSup= moment(hora,"HH:mm:ss").diff(moment(limiteSup, "HH:mm:ss")); //si es MENOR a 0 esta dentro de tiempo
                        console.log("aqui es el limite superio: " + limiteInf);
                        console.log("aqui es el limite inferior: " + limiteSup);
                        if (((limiteInf < 0 && limiteSup < 0) || (limiteInf > 0 && limiteSup > 0)) ) {
                            console.log("fuera de tiempo"+ hora);
                            dispatch(newCheckA( user.uid, "", dia, "Checkin out of time", "", ""));
                        }
                        dispatch(newCheck( user.uid, dia, time, "", "", "", "", ""));
                        setTimeout(function(){
                            document.getElementById("Estado").innerHTML  = "Online";
                            document.getElementById("Estado").style.color = "#1E8449";                   
                        }, 2000)
                    }
                    
                }else{
                    Swal.fire('Welcome!','You checkIn at '+time,'success');
                    
                    let limite
                    if (usuarios[0].shift === "midday 10:00") {
                        limite = "10:00:00";
                    }
                    if (usuarios[0].shift === "midday 09:00" || usuarios[0].shift === "QA midday") {
                        limite ="09:00:00";
                    }
                    if (usuarios[0].shift === "morning" || usuarios[0].shift === "QA morning") {
                        limite = "06:00:00";
                    }
                    if (usuarios[0].shift === "afternoon" || usuarios[0].shift === "QA afternoon") {
                        limite = "15:00:00";
                    }
                    if (usuarios[0].shift === "night" || usuarios[0].shift === "QA night") {
                        limite = "00:00:00";
                    }
                    let limiteInf = moment(limite).add(-10, 'minutes').format("HH:mm:ss"); 
                    let limiteSup = moment(limite).add(10, 'minutes').format("HH:mm:ss");
                    console.log("aqui es el limite superio: " + limiteInf);
                    console.log("aqui es el limite inferior: " + limiteSup);
                    limiteInf= moment(hora,"HH:mm:ss").diff(moment(limiteInf, "HH:mm:ss")); //si es MAYOR a 0 esta dentro de tiempo
                    limiteSup= moment(hora,"HH:mm:ss").diff(moment(limiteSup, "HH:mm:ss")); //si es MENOR a 0 esta dentro de tiempo
                    console.log("aqui es el limite superio: " + limiteInf);
                    console.log("aqui es el limite inferior: " + limiteSup);
                    if (((limiteInf < 0 && limiteSup < 0) || (limiteInf > 0 && limiteSup > 0)) ) {
                        console.log("fuera de tiempo"+ hora);
                        dispatch(newCheckA( user.uid, "", fecha, "Checkin out of time", "", ""));
                    }
                    dispatch(newCheck( user.uid, fecha, time, "", "", "", "", ""));
                    setTimeout(function(){
                        document.getElementById("Estado").innerHTML  = "Online";
                        document.getElementById("Estado").style.color = "#1E8449";                   
                    }, 2000) 
                }
            }
        }
        if(formValues.check === "Emergency-In"){ 
            if(estadoExtra){
                Swal.fire('Error!','You are on overtime! ','error');
            }else{
                if(estado){
                    if(estado.checkout === ""){
                        Swal.fire('Error!','You are on turn! ','error');
                    }else{
                        dispatch(newCheckE( user.uid, fecha, time, "", 0, "Pending", ""));
                    }
                }else{
                    dispatch(newCheckE( user.uid, fecha, time, "", 0, "Pending", ""));
                }
            }
        }
        if(formValues.check === "Emergency-Out"){
            let repetido = myChecksE.find(checks=> checks.fecha === fecha && checks.total ===0);
            let diaAtras = moment(fecha).add(-1, 'day').format("MM-DD-YYYY");
            console.log(repetido);
            
            if(repetido){
                if (repetido.checkout.length === 0) {
                    let total= moment(time, "MM-DD-YYYY HH:mm:ss").diff(moment(repetido.checkin, "MM-DD-YYYY HH:mm:ss"));
                    total = (total/3600000);
                    Swal.fire('Thanks!','Thank you for your support!','success');
                    dispatch(updateCheckE( repetido.id, user.uid, repetido.fecha, repetido.checkin, time, total, "Pending", ""));
                    window.location.reload();
                }else{
                    Swal.fire('Error!','You have already made chekout','error');
                }
            }else{
                repetido = myChecksE.find(checks=> checks.fecha === diaAtras && checks.total ===0);
                
                if(repetido){
                    if (repetido.checkout.length === 0) {
                        let total= moment(time, "MM-DD-YYYY HH:mm:ss").diff(moment(repetido.checkin, "MM-DD-YYYY HH:mm:ss"));
                        total = (total/3600000);
                        Swal.fire('Thanks!','Thank you for your support!','success');
                        dispatch(updateCheckE( repetido.id, user.uid, repetido.fecha, repetido.checkin, time, total, "Pending", ""));
                    }else{
                        Swal.fire('Error!','You have already made chekout','error');
                    }
                }else{
                    Swal.fire('Error!','You are not on overtime','error');
                }
            }
            
        }
        if(formValues.check === "Check-Out" || formValues.check === "Break" || formValues.check === "Return" ){
            let repetido = myChecks.find(checks=> checks.fecha === fecha);
            let repetidoA = myChecksA.find(checkA=> checkA.fecha === fecha);

            if(repetido){
                
                if (formValues.check === "Return") {
                    //si guardar
                    if (repetido.returnC.length === 0) {
                        //No guardar
                        if (repetido.breakC.length === 0) {
                            Swal.fire('No!','You did not go out to break','error');
                        }
                        //si guardar
                        else{
                            let total= moment(time, "MM-DD-YYYY HH:mm:ss").diff(moment(repetido.breakC, "MM-DD-YYYY HH:mm:ss"));
                            console.log(total);
                            total = (total/3600000);
                            console.log(total);
                            Swal.fire('Welcome back!','Welcome back. Success on your turn','success');
                            let limite  = moment(usuarios[0].breakT,"HH:mm:ss");
                            let limiteInf = moment(limite).add(55, 'minutes').format("HH:mm:ss"); 
                            let limiteSup = moment(limite).add(65, 'minutes').format("HH:mm:ss");
                            limiteInf= moment(hora,"HH:mm:ss").diff(moment(limiteInf, "HH:mm:ss")); //si es MAYOR a 0 esta dentro de tiempo
                            limiteSup= moment(hora,"HH:mm:ss").diff(moment(limiteSup, "HH:mm:ss")); //si es MENOR a 0 esta dentro de tiempo
                            if (((limiteInf < 0 && limiteSup < 0) || (limiteInf > 0 && limiteSup > 0)) ) {
                                console.log(repetidoA);
                                if(repetidoA){
                                    dispatch(updateCheckA(repetidoA.id, repetidoA.uid, repetidoA.idChecker, repetidoA.fecha,  (repetidoA.anomalia + ", Return out of time"),  repetidoA.accion, repetidoA.name));
                                }else{
                                    dispatch(newCheckA( user.uid, "", fecha, "Return out of time", "", ""));
                                }
                            }                           
                            dispatch(updateCheck(repetido.id, user.uid, repetido.fecha, repetido.checkin, repetido.checkout, repetido.breakC, time, total, repetido.total));
                            setTimeout(function(){
                                document.getElementById("Estado").innerHTML  = "Online";
                                document.getElementById("Estado").style.color = "#1E8449";                   
                            }, 2000)
                        }
                    }
                    //No guardar
                    else{
                        Swal.fire('No!','You have already made return','error');
                    }
                }

                if (formValues.check === "Break" ) {
                    //si guardar
                    if (repetido.breakC.length === 0) {
                        Swal.fire('See you!','Enjoy your break','success');
                        let limite  = moment(usuarios[0].breakT,"HH:mm:ss");
                        console.log(limite);
                        
                        let limiteInf = moment(limite).add(-5, 'minutes').format("HH:mm:ss"); 
                        let limiteSup = moment(limite).add(5, 'minutes').format("HH:mm:ss");
                        console.log(limiteInf);
                        console.log(limiteSup);
                        limiteInf= moment(hora,"HH:mm:ss").diff(moment(limiteInf, "HH:mm:ss")); //si es MAYOR a 0 esta dentro de tiempo
                        limiteSup= moment(hora,"HH:mm:ss").diff(moment(limiteSup, "HH:mm:ss")); //si es MENOR a 0 esta dentro de tiempo
                        if (((limiteInf < 0 && limiteSup < 0) || (limiteInf > 0 && limiteSup > 0)) ) {
                                console.log(repetidoA);
                                if(repetidoA){
                                    dispatch(updateCheckA(repetidoA.id, repetidoA.uid, repetidoA.idChecker, repetidoA.fecha,  (repetidoA.anomalia + ", Break out of time"),  repetidoA.accion, repetidoA.name));
                                    support = true;
                                }else{
                                    dispatch(newCheckA( user.uid, "", fecha, "Break out of time", "", ""));
                                    support = true;
                                }
                            
                        }
                        dispatch(updateCheck(repetido.id, user.uid, repetido.fecha, repetido.checkin, repetido.checkout, time, repetido.returnC, repetido.totalB, repetido.total));
                        
                        setTimeout(function(){
                            document.getElementById("Estado").innerHTML  = "Break";
                            document.getElementById("Estado").style.color = "#F1C40F";                  
                        }, 2000)
                    }
                    //No guardar
                    else{
                        Swal.fire('No!','You are already in break','error');
                    }
                }

                if (formValues.check === "Check-Out") {
                    //si guardar
                    if (repetido.checkout.length === 0 && ((repetido.breakC.length === 0 && repetido.returnC.length === 0)|| (repetido.breakC.length !== 0 && repetido.returnC.length !== 0))) {
                        let total= moment(time, "MM-DD-YYYY HH:mm:ss").diff(moment(repetido.checkin, "MM-DD-YYYY HH:mm:ss"));
                        console.log(repetido.checkout.length);
                        console.log(repetido.breakC.length);
                        console.log(repetido.returnC.length);
                        total = (total/3600000);
                        console.log(total)
                        total = total - repetido.totalB;
                        Swal.fire('See you tomorrow!','Have a good rest!','success');
                        let limite
                        if (usuarios[0].shift === "midday 10:00") {
                            limite = "19:00:00";
                        }
                        if (usuarios[0].shift === "midday 09:00" || usuarios[0].shift === "QA midday") {
                            limite = "18:00:00";
                        }
                        if (usuarios[0].shift === "morning" || usuarios[0].shift === "QA morning") {
                            limite = "15:00:00";
                        }
                        if (usuarios[0].shift === "afternoon" || usuarios[0].shift === "QA afternoon") {
                            limite = "00:00:00";
                        }
                        if (usuarios[0].shift === "night" || usuarios[0].shift === "QA night") {
                            limite = "06:00:00";
                        }
                        let limiteInf = moment(limite).add(-10, 'minutes').format("HH:mm:ss"); 
                        let limiteSup = moment(limite).add(10, 'minutes').format("HH:mm:ss");
                        limiteInf= moment(hora,"HH:mm:ss").diff(moment(limiteInf, "HH:mm:ss")); //si es MAYOR a 0 esta dentro de tiempo
                        limiteSup= moment(hora,"HH:mm:ss").diff(moment(limiteSup, "HH:mm:ss")); //si es MENOR a 0 esta dentro de tiempo
                        if (((limiteInf < 0 && limiteSup < 0) || (limiteInf > 0 && limiteSup > 0)) ) {
                            
                                if(repetidoA){
                                    dispatch(updateCheckA(repetidoA.id, repetidoA.uid, repetidoA.idChecker, repetidoA.fecha,  (repetidoA.anomalia + ", Checkout out of time"),  repetidoA.accion, repetidoA.name));
                                }else{
                                    dispatch(newCheckA( user.uid, "", fecha, "Checkout out of time", "", ""));
                                }
                        }
                        console.log("Checkout!");
                        dispatch(updateCheck(repetido.id, user.uid, repetido.fecha, repetido.checkin, time, repetido.breakC, repetido.returnC, repetido.totalB, total));
                        
                        setTimeout(function(){
                            document.getElementById("Estado").innerHTML  = "Offline";
                            document.getElementById("Estado").style.color = "#A93226";                 
                        }, 2000)
                    }
                    //No guardar
                    else{
                        Swal.fire('No!','Your turn is over','error');
                    }
                }
                
            }else{
                //No guardar
                if (hora2==="00") {
                    
                    let diaAtras = moment(fecha).add(-1, 'day').format("MM-DD-YYYY");
                    console.log(diaAtras);
                    let repetido = myChecks.find(checks=> checks.fecha === diaAtras);
                    let repetidoA = myChecksA.find(checkA=> checkA.fecha === diaAtras);

                    if (formValues.check === "Check-Out") {
                        //si guardar
                        if (repetido.checkout.length === 0) {
                            let total= moment(time, "MM-DD-YYYY HH:mm:ss").diff(moment(repetido.checkin, "MM-DD-YYYY HH:mm:ss"));
                            total = (total/3600000);
                            console.log(total)
                            total = total - repetido.totalB;
                            console.log(total)
                            Swal.fire('Welcome back!','Welcome back. Success on your turn','success');
                            let limite
                            if (usuarios[0].shift === "midday 10:00") {
                                limite = "19:00:00";
                            }
                            if (usuarios[0].shift === "midday 09:00" || usuarios[0].shift === "QA midday") {
                                limite = "18:00:00";
                            }
                            if (usuarios[0].shift === "morning" || usuarios[0].shift === "QA morning") {
                                limite = "15:00:00";
                            }
                            if (usuarios[0].shift === "afternoon" || usuarios[0].shift === "QA afternoon") {
                                limite = "00:00:00";
                            }
                            if (usuarios[0].shift === "night" || usuarios[0].shift === "QA night") {
                                limite = "06:00:00";
                            }
                            let limiteInf = moment(limite).add(-10, 'minutes').format("HH:mm:ss"); 
                            let limiteSup = moment(limite).add(10, 'minutes').format("HH:mm:ss");
                            limiteInf= moment(hora,"HH:mm:ss").diff(moment(limiteInf, "HH:mm:ss")); //si es MAYOR a 0 esta dentro de tiempo
                            limiteSup= moment(hora,"HH:mm:ss").diff(moment(limiteSup, "HH:mm:ss")); //si es MENOR a 0 esta dentro de tiempo
                            if (((limiteInf < 0 && limiteSup < 0) || (limiteInf > 0 && limiteSup > 0)) ) {
                                console.log(repetidoA);
                                if(repetidoA){
                                    dispatch(updateCheckA(repetidoA.id, repetidoA.uid, repetidoA.idChecker, repetidoA.fecha,  (repetidoA.anomalia + ", Checkout out of time"),  repetidoA.accion, repetidoA.name));
                                }else{
                                    dispatch(newCheckA( user.uid, "", fecha, "Checkout out of time", "", ""));
                                }
                            }
                            dispatch(updateCheck(repetido.id, user.uid, repetido.fecha, repetido.checkin, time, repetido.breakC, repetido.returnC, repetido.totalB, total));
                            setTimeout(function(){
                                document.getElementById("Estado").innerHTML  = "Offline";
                                document.getElementById("Estado").style.color = "#A93226";                 
                            }, 2000)
                        }
                        //No guardar
                        else{
                            Swal.fire('No!','Your turn is over','error');
                        }
                    }
                }
            }
        }
    }
    const handleSubmitForm2 = (e) => {
        e.preventDefault();
    }

    return(
        <div className="container-fluid  animate__animated animate__fadeIn mt-2">
            <hr />
            <h1 className="text-center" style={{ color: '#ffffff' }}>MASCHECKER</h1>
            <hr />
            {(estado)&&
             <h4 className="text-center" style={{ color: '#ffffff' }}>Status:
                {(estado.breakC !== "" && estado.checkin !== "" && estado.returnC === "" && estado.checkout ==="" && !estadoExtra)&&
                    <h4 id="Estado" style={{ color: '#F1C40F' }}>Break</h4> 
                } 
                {(estado.checkin !== "" && estado.breakC ==="" && estado.checkout ==="" && estado.returnC === ""&& !estadoExtra)&&
                    <h4 id="Estado" style={{ color: '#1E8449' }}>Online</h4> 
                }
                {(estado.checkin !== "" && estado.checkout !== "" && !estadoExtra)&&
                    <h4 id="Estado" style={{ color: '#A93226' }}>Offline</h4> 
                }
                {(estado.returnC !== "" && estado.checkout === ""&& !estadoExtra)&&
                    <h4 id="Estado" style={{ color: '#1E8449' }}>Online</h4> 
                }
                {(estadoExtra)&&
                    <h4 id="Estado" style={{ color: '#1E8449' }}>Emergency!</h4> 
                }
                
            </h4>
            }
            {(!estado && !estadoExtra)&&
                <h4 id="Estado" className="text-center" style={{ color: '#ffffff' }}>Status: No activity today </h4>
            }
            {(!estado && estadoExtra)&&
                <h4 id="Estado" className="text-center" style={{ color: '#1E8449' }}>Emergency! </h4>
            }
           
            
                
                <BrowserView>
                <div className="row ">
                    <div className="col-4">
                        <h4 style={{ color: '#ffffff' }}>Checker</h4>
                        <hr /> 
                        {(!usuarios)&&
                            // <Clock className="d-flex justify-content-center reloj" format={'HH:mm:ss'} ticking={true} timezone={'us/central'} />
                            <Clock className="d-flex justify-content-center reloj" format={'HH:mm:ss'} ticking={true} timezone={'Asia/Calcutta'} />
                        }
                        {/*(usuarios && (usuarios[0].hub==="MX"))&&
                            // <Clock className="d-flex justify-content-center reloj" format={'HH:mm:ss'} ticking={true} timezone={'us/central'} />
                            <Clock className="d-flex justify-content-center reloj" format={'HH:mm:ss'} ticking={true} timezone={'America/Mexico_City'} />
                        */}
                        {(usuarios && (usuarios[0].hub==="IN"))&&
                            <Clock className="d-flex justify-content-center reloj" format={'HH:mm:ss'} ticking={true} timezone={'Asia/Calcutta'} />
                        }
                        {/*(usuarios && (usuarios[0].hub==="BR"))&&
                            <Clock className="d-flex justify-content-center reloj" format={'HH:mm:ss'} ticking={true} timezone={'America/Sao_Paulo'} />
                        */}
                        {(!usuarios)&&
                        <form className="container" onSubmit={handleSubmitForm}>
                            <div className="form-group">
                                <select className="form-control" name="check"  onChange={ handleInputChange }>
                                    <option selected>Select a check</option>
                                    <option value="Check-In"> Check-In </option>
                                    <option value="Check-Out"> Check-Out </option>
                                    <option value="Emergency-In"> Emergency-In </option>
                                    <option value="Emergency-Out"> Emergency-Out </option>
                                    <option value="Break"> Break </option>
                                    <option value="Return"> Return </option>
                                </select>
                            </div>
                            <hr />
                            <button
                                type="submit"
                                className="btn btn-outline-primary btn-block">
                                <i className="far fa-save"></i>
                                <span> Save </span>
                            </button>
    
                        </form>
                        
                    }
                    {(usuarios && usuarios[0].status === "Active")&&
                        <form className="container" onSubmit={handleSubmitForm}>
                            <div className="form-group">
                                <select className="form-control" name="check"  onChange={ handleInputChange }>
                                    <option selected>Select a check</option>
                                    <option value="Check-In"> Check-In </option>
                                    <option value="Check-Out"> Check-Out </option>
                                    <option value="Emergency-In"> Emergency-In </option>
                                    <option value="Emergency-Out"> Emergency-Out </option>
                                    <option value="Break"> Break </option>
                                    <option value="Return"> Return </option>
                                </select>
                            </div>
                            <hr />
                            <button
                                type="submit"
                                className="btn btn-outline-primary btn-block">
                                <i className="far fa-save"></i>
                                <span> Save </span>
                            </button>
    
                        </form>
                        
                    }
                    {(usuarios && usuarios[0].status !== "Active")&&
                        <h4 style={{ color: '#ffffff' }}> You cannot use the checker, until You are off of your {usuarios[0].status} Period</h4>
                    } 
                        </div>
                    <div className="col-8">
                        <h4 style={{ color: '#ffffff' }}>My hours</h4>
                        <hr />
                        <form className="container" onSubmit={handleSubmitForm2}>
                        <div className="row">
                            
                                <div className="col-sm-4">
                                    <p style={{ color: '#ffffff' }}>From</p>
                                    <DatePicker
                                    onChange={onChange}
                                    value={from}
                                    className="picker"
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <p style={{ color: '#ffffff' }}>To</p>
                                    <DatePicker
                                    onChange={onChange2}
                                    value={to}
                                    className="picker"
                                    />
                                </div>
                        </div>
                        </form>
                        <table class="table table-dark">
                        <thead>
                            <tr>
                                <th scope="col">Day</th>
                                <th scope="col">Hours</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        {
                            checksFilter.map(check => {
                                return ( 
                                    <tr>
                                        <td>{check.fecha}</td>
                                        <td>{check.total}</td>
                                        {(check.total=== null && check.fecha === hoy)&&
                                        <td style={{ color: '#1E8449' }}>You are already active</td> 
                                        }
                                        {(check.total=== null && check.fecha !== hoy)&&
                                        <td style={{ color: '#F1C40F' }}>Please contact your RRHH agent to verify your situation</td> 
                                        }
                                        {(check.total > 8)&&
                                        <td style={{ color: '#A93226' }}>Exceeded 8 hours per day, the excess will not be paid until authorized by the supervisor.</td> 
                                        }
                                    </tr>
                                )
                            })
                        }
                        </table>
                        <h4 style={{ color: '#ffffff' }}>Overtime</h4>
                        <hr />
                        <table class="table table-dark">
                            <tr>
                                <th scope="col">Day</th>
                                <th scope="col">Hours</th>
                                <th scope="col">Status</th>
                            </tr>
                        {
                            checksFilterE.map(check => {
                                return ( 
                                    <tr>
                                        <td>{check.fecha}</td>
                                        <td>{check.total}</td>
                                        
                                        {(check.approval === "Accepted")&&
                                        <td style={{ color: '#1E8449' }}>Accepted</td> 
                                        }
                                        
                                        {(check.approval === "Rejected")&&
                                        <td style={{ color: '#A93226' }}>Rejected</td> 
                                        }
                                        {(check.approval === "Pending")&&
                                        <td style={{ color: '#F1C40F' }}>Pending</td> 
                                        }
                                    </tr>
                                )
                            })
                        }
                        </table>
                    </div>
                    </div>
                </BrowserView>
                <MobileView>
                    <h1 style={{ color: '#ffffff' }}>The checker cannot be used on mobile devices.</h1>
                </MobileView>    
            
        </div>
    )
}