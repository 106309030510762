import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {NavLink } from 'react-router-dom'

const NavbarSupProduct = () => {
  return (
  <>
  {
<Dropdown>
        <Dropdown.Toggle style={{ color:'#BFCBC6', background: '#283040', borderColor: '#283040'}}>
            Productivity
        </Dropdown.Toggle>
            <Dropdown.Menu>
                
                        <Dropdown>
                        
                            <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/agents" style={{ color: '#283040'}}>
                                Agents
                            </NavLink>  
                        </Dropdown>
                
            </Dropdown.Menu>
</Dropdown>

  }



  </>);
};

export default NavbarSupProduct;
