import React, { useState , useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { GetUser } from '../../selectors/getUsers';
import moment from 'moment';
import "./style.css"

import { GetCheck, GetCheckE } from '../../selectors/getCheck';
import { CSVLink, CSVDownload } from "react-csv";
import DatePicker from 'react-date-picker';
import Swal from "sweetalert2";
import Loader from '../Loader/Loader';
const baseUrl = process.env.REACT_APP_API_URL;

let initEvent = {
    check: '', 
}

export const HoursPay = () => {

    const { role, uid } = useSelector( state => state.auth );
    console.log("role: ", role, " uid: "+uid);

    const dispatch = useDispatch();
    //----------------------------------------------
    let date = moment().tz("Asia/Calcutta").format("YYYY-MM-DD");
    const [dateFormat, setDateFormat]= useState(date);
    const [dateFormat2, setDateFormat2]= useState(date);
    const [loader, setLoader]= useState(true);//use state loader
    const [ready, setReady]= useState(false);
    const [hours, setHours]=useState ([]);
    const [overtimes, setOvertimes]=useState ([]);
    const [btn, setBtn] = useState(false);//Nuevo boton

    useEffect(async() => {
        
        console.log("-------- execute use effect --------")
        setLoader(true);
        setReady(false); //Mantener ocultos los botones de Descarga
        const token = localStorage.getItem('token') || '';
        console.log(token);
        const dateArray=dateFormat.split('-');
        const dateArray2=dateFormat2.split('-');
        const dateToQuery=dateArray[1]+"-"+dateArray[2]+"-"+dateArray[0]
        const dateToQuery2=dateArray2[1]+"-"+dateArray2[2]+"-"+dateArray2[0]
        //checker
        await axios.get(`${baseUrl}/payroll/${dateToQuery}/${dateToQuery2}`, { headers: {"x-token" : `${token}`} })
                    .then(async res => 
                        {
                            console.log('profile is:', res.data);

                            setLoader(false);
                            setReady(true); //Habilitar Botones de Descarga
                            console.log(res.data.finalData)
                            //setHours(res.data.finalData);
                            await loopcheckpayroll(res.data.finalData);
                            
                        })
                    .catch(error => 
                            console.log(error
                        ))
        //checker extras
        await axios.get(`${baseUrl}/payroll/extras/${dateToQuery}/${dateToQuery2}`, { headers: {"x-token" : `${token}`} })
                    .then(async res => 
                        {
                            console.log('profile is:', res.data);
                            setLoader(false);
                            setReady(true); //Habilitar Botones de Descarga
                            await loopcheckover(res.data.result);
                        })
                    .catch(error => 
                            console.log(error
                        ))
                        //Confirmación de Descarga
                        Swal.fire({
                            icon: 'success',
                            title: 'You can download the files',
                            showConfirmButton: false,
                            timer: 3000
                          })
    }, [btn])

    const onChangeDate = (e) =>{
        e.preventDefault();
        const dateArray=e.target.value.split("-");
        const currentDate = moment().tz("Asia/Calcutta");
        const queryDate= moment(dateArray[0]+""+dateArray[1]+""+(dateArray[2]));
        console.log("fecha construida: "+ queryDate)
        console.log("fecha actual: "+currentDate+" fecha consulta: "+queryDate); 
        if(queryDate>currentDate){
            Swal.fire('Error',"Unable to query a date in the future", 'error');
            return;
        }else{
            setDateFormat(e.target.value);
        }
    }

    const onChangeDate2 = (e) =>{
        e.preventDefault();
        const dateArray=e.target.value.split("-");;
        const currentDate = moment().tz("Asia/Calcutta");
        const queryDate= moment(dateArray[0]+""+dateArray[1]+""+(dateArray[2]));
        console.log("fecha construida: "+ queryDate)
        console.log("fecha actual: "+currentDate+" fecha consulta: "+queryDate); 
        if(queryDate>currentDate){
            Swal.fire('Error',"Unable to query a date in the future", 'error');
            return;
        }else{
            setDateFormat2(e.target.value);
        }
    }

     /*Conf boton*/
     const onChangeBtn = (e) => {
        e.preventDefault();
        setBtn((btn === true) ? false : true);
    }
    /*---------*/

    //-------------------------Payroll Checker---------------------------------
    const loopcheckpayroll= async(checker)=>{
        console.log(checker);
        const arrAux=[];
    checker.map(checkA => {
    
                            
                        //     const obj = {
                                
                        //         "Name": checkA.name, 
                               
                        //         "Date": checkA.fechaChecker, 
                        //         "Total": checkA.total,
                        //     };
                        //    arrAux.push(obj); 
                        //console.log(JSON.stringify(checkA))
                        //const stringify=JSON.stringify(checkA)
                        //const parse=JSON.parse(stringify);
                        //console.log(parse)
                        arrAux.push(checkA); 
    }) 
    
    await setHours(arrAux);
    console.log("hours");
    console.log(hours);
}

//-------------------------Payroll Overtimes---------------------------------
const loopcheckover= async(checker)=>{
    console.log(checker);
    const arrAux=[];
checker.map(checkA => {

                        
                        const obj = {
                            
                            "Name": checkA.name, 
                            
                            "Date": checkA.fechaExtra, 

                            "Approval": checkA.approval,

                            "Supervisor": checkA.supervisor,
                           
                            "Total": checkA.total,
                        };
                       arrAux.push(obj); 
                
}) 
setOvertimes(arrAux);
}

    return (
        <div>
            <hr />
            <div className="container-fliud center text-center">
                {(role==="Admin" || role==="Payroll"  )&&
                    <div>
                        <h1 className="text-center" style={{ color: '#ffffff' }}>HOURS TO PAY</h1>
                    </div>
                }
                {(role==="Agente" || role==="AgenteDB" || role==="RH" || role==="CoordQA" || role==="CoordDB" || role==="CoordCD")&&
                    <div>
                        <h1 className="text-center" style={{ color: '#ffffff' }}>Access Denied</h1>
                    </div>
                }
                <hr />
            </div>
            <div  className='row col-lg-7 mx-auto'>
                        <div className='row col-lg-4 mx-auto' >
                            <label className='col-lg-12 text-white text-left'>From</label>
                            <input type="date" className ="form-control" name='FechaInicial' value={dateFormat} max={date} onChange={onChangeDate}>
                                
                            </input>
                        </div>
                        <div className='row col-lg-4 mx-auto'>
                        <label className='col-lg-12 text-white text-left'>To</label>
                            <input type="date" className ="form-control" name='FechaFinal' value={dateFormat2} max={date} onChange={onChangeDate2}>
                                
                            </input>

                        </div>
            </div>
                <p></p>
                {/*Search button by date range.*/}
            <div className='d-flex justify-content-center'>
                    <button className="btn btn-outline-info" onClick={onChangeBtn}><i className="fa-solid fa-magnifying-glass"></i>  Search</button>
                </div>
                <p></p>

            {(role==="Admin" || role==="Payroll" )&&(ready)&& //var Ready = Don't show download buttons until info is uploaded. By Yaelo
                <div className=""> 

                    <div className="d-flex justify-content-center">
                        <button type="button" class="btn btn-success">
                            <i class="fas fa-file-csv"></i> 
                            <CSVLink 
                                style={{ color: '#ffffff' }} 
                                filename={"Checker.csv"} 
                                // headers={headers}
                                data={hours}
                                >Download Checker
                            </CSVLink>
                            
                        </button>
                        <span />
                        &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
                        <button type="button" class="btn btn-success">
                            <i class="fas fa-file-csv"></i> 
                            
                            <CSVLink 
                                style={{ color: '#ffffff' }} 
                                filename={"Overtimes.csv"} 
                                data={overtimes}
                                
                                >Download Overtimes
                            </CSVLink>
                        </button>
                    </div>
                 
                </div>
            }

            {(role==="Admin" || role==="Payroll" )&&
                <div className="">
                    {//---------------------------------------- start loader -------------------------------------
                            
                            (loader)&&
                            <div className='col-lg-12 row'>
                                <div class="spinner-border text-white col-lg-4 mx-auto text-center" role="status" key={"status"}>
                                    
                                    <Loader/>
                                    <h3>Loading...</h3>
                                </div>
                            </div>
                //---------------------------------------- loader end ----------------------------------------
                }
                </div>
            }
        </div>
    )
}

