export const types = {

    docAddNew:'[doc] Add new',
    docUpdate:'[doc] Add update',
    docDelete:'[doc] Add delete',
    docLoaded:'[doc] Loaded',
    docActive: '[doc] active',

    cdAddNew:'[cd] Add new',
    cdUpdate:'[cd] Add update',
    cdDelete:'[cd] Add delete',
    cdLoaded:'[cd] Loaded',
    cdActive: '[cd] active',

    customAddNew:'[custom] Add new',
    customUpdate:'[custom] Add update',
    customDelete:'[custom] Add delete',
    customLoaded:'[custom] Loaded',
    customActive: '[custom] active',

    ddAddNew:'[dd] Add new',
    ddUpdate:'[dd] Add update',
    ddDelete:'[dd] Add delete',
    ddLoaded:'[dd] Loaded',
    ddActive: '[dd] active',
    
    paisLoaded: '[pais] Loaded',

    srAddNew:'[sr] Add new',
    srUpdate:'[sr] Add update',
    srDelete:'[sr] Add delete',
    srLoaded:'[sr] Loaded',
    srActive: '[sr] active',

    authCheckingFinish: '[auth] Finish login state',
    authStartLogin: '[auth] Start login',
    authLogin: '[auth] login',
    authStartRegister: '[auth] Start register',
    authStartTokenRenew: '[auth] Start token renew',
    authLogout: '[auth] logout',
    authLoaded: '[auth] Loaded',

    uiOpenModalDoc: '[ui] Open modal',
    uiCloseModalDoc: '[ui] Close modal',
    uiOpenModalBug: '[ui] Open modal bug',
    uiCloseModalBug: '[ui] Close modal bug',
    uiOpenReporte: '[ui] Open Reporte',
    uiCloseReporte: '[ui] Close Reporte',
    uiOpenReporteT: '[ui] Open ReporteT',
    uiCloseReporteT: '[ui] Close ReporteT',
    uiOpenModalSr: '[ui] Open modal',
    uiCloseModalSr: '[ui] Close modal',

    checkloads:'[ui] Loaded',
    checkExtrasloads:'[check] Loaded',
    checkAlertsloads:'[alerts] Loaded',
    spreadLoaded:'[spread] Loaded',
    spreadLoadedNom:'[spreadNom] Loaded',
    spreadLoadedRH:'[spreadRH] Loaded',
}