import { fetchConToken, fetchSinToken } from "../helpers/fetch"
import { types } from "../types/types";
import Swal from 'sweetalert2';



export const startLogin = ( email, password ) => {
    return async( dispatch ) => {

        const resp = await fetchSinToken( 'auth', { email, password }, 'POST' );
        const body = await resp.json();
        console.log(body);
        if( body.ok ) {
            localStorage.setItem('token', body.token );
            localStorage.setItem('token-init-date', new Date().getTime() );
            
            dispatch(login({
                uid: body.uid,
                name: body.name,
                role: body.role,
                status:body.status, 
                shift:body.shift, 
                hub:body.hub, 
                breakT:body.breakT
            }) )
        }else{
            Swal.fire('Error', body.error, 'error');
        }
        

    }
}

export const startRegister = ( email, password, name, role, status, shift, hub, breakT, dayOff ) => {
    return async( dispatch ) => {
        console.log(email, password, name, role, status, shift, hub, breakT, dayOff)
        const resp = await fetchSinToken( 'auth/new', { email, password, name, role, status, shift, hub, breakT, dayOff }, 'POST' );
        const body = await resp.json();

        if( body.ok ) {
            Swal.fire(
                'Saved!',
                'User successfully created!',
                'success'
              )
              window.location.reload();
        } else {
            Swal.fire('Error', body.error, 'error');
        }


    }
}

export const startChecking = () => {
    return async(dispatch) => {

        const resp = await fetchConToken( 'auth/renew' );
        const body = await resp.json();

        if( body.ok ) {
            localStorage.setItem('token', body.token );
            localStorage.setItem('token-init-date', new Date().getTime() );

            dispatch( login({
                uid: body.uid,
                name: body.name,
                role: body.role,
                status:body.status, 
                shift:body.shift, 
                hub:body.hub, 
                breakT:body.breakT
            }) )
        } else {
            dispatch( checkingFinish() );
        }
    }
}

const checkingFinish = () => ({ type: types.authCheckingFinish });

const login = ( user ) => ({
    type: types.authLogin,
    payload: user
});

export const startLogout = () => {
    return ( dispatch ) => {

        localStorage.clear();
        dispatch( logout() );
    }
}


const logout = () => ({ type: types.authLogout })

export const userUpdate = ( name, email, password, role, id, status, shift, hub, breakT, photo, dayOff) => {
    return async( dispatch ) => {

        const resp = await fetchConToken( `auth/${id}`, { name, email, password, role, status, shift, hub, breakT, photo, dayOff }, 'PUT' );
        const body = await resp.json();
        console.log(body);
        if( body.ok ) {
            Swal.fire(
                'Saved!',
                'User successfully created!',
                'success'
              )
              window.location.reload();
        } else {
            Swal.fire('Error', body.error, 'error');
        }
        

    }
}
export const userDelete = ( id) => {
    return async( dispatch ) => {
        
        const resp = await fetchConToken( `auth/${id}`, {id},'DELETE' );

        const body = await resp.json();

        if( body.ok ) {
            Swal.fire(
                'Deleted!',
                'User successfully deleted!',
                'success'
              )
            window.location.reload();
        } else {
            Swal.fire('Error', body.error, 'error');
        }
        

    }
}

export const getSpread = ( ) => {
    return async( dispatch ) => {

        try {
            let resp = await fetchConToken( 'spread');
            let body = await resp.json();

            let arreglo = body.arreglo;

            console.log(arreglo);
            
            await dispatch(spreadLoaded(arreglo));

            /*resp = await fetchConToken( 'spread/nom' );
            body = await resp.json();

            arreglo = body.arreglo;
            console.log(arreglo);
            await dispatch(spreadLoadedNom(arreglo));

            resp = await fetchConToken( 'spread/rh');
            body = await resp.json();

            arreglo = body.arreglo;
            console.log(arreglo);
            await dispatch(spreadLoadedRH(arreglo));*/


        } catch (error) {
      
        }
    }
}
const spreadLoaded = (arreglo) =>({
    type: types.spreadLoaded,
    payload: arreglo
})

export const getSpreadNom = ( ) => {
    return async( dispatch ) => {
        console.log("aqui");
        try {
            
            
        } catch (error) {
      
        }
    }
}
const spreadLoadedNom = (arreglo) =>({
    type: types.spreadLoadedNom,
    payload: arreglo
})

export const getSpreadRH = ( ) => {
    return async( dispatch ) => {
        
        try {
            const resp = await fetchConToken( 'spread/rh');
            const body = await resp.json();

            const arreglo = body.arreglo;
            dispatch(spreadLoadedRH(arreglo));
            
        } catch (error) {
      
        }
    }
}
const spreadLoadedRH = (arreglo) =>({
    type: types.spreadLoadedRH,
    payload: arreglo
})


export const reporteExc = ( Date, job, agent, seconds, jobType ) => {
    return async( dispatch ) => {
        console.log(Date);
        try {
            const resp = await fetchConToken( 'spread', { Date, job, agent, seconds, jobType }, 'POST' );
            const body = await resp.json();

            console.log(body);
            if( body.ok ) {
                Swal.fire(
                    'Good job!',
                    'Thanks!',
                    'success'
                  )
            } else {
                Swal.fire('Error', "Please log in again", 'error');
                dispatch( startLogout() );
            }
            
        } catch (error) {
      
        }
    }
}
export const reporteT = ( Date,  AgentName, IdJob, Reason ) => {
    return async( dispatch ) => {
        console.log(Date);
        try {
            const resp = await fetchConToken( 'spread/trucha', { Date,  AgentName, IdJob, Reason }, 'POST' );
            const body = await resp.json();

            console.log(body);
            if( body.ok ) {
                Swal.fire(
                    'Good job!',
                    'Thanks!',
                    'success'
                  )
            } else {
                Swal.fire('Error', "Please log in again", 'error');
                dispatch( startLogout() );
            }
            
        } catch (error) {
      
        }
    }
}

export const reporteB = ( Fecha, Agente, Tipo, Observacion ) => {
    return async( dispatch ) => {
        console.log(Date);
        try {
            const resp = await fetchConToken( 'spread/bug', { Fecha, Agente, Tipo, Observacion }, 'POST' );
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire(
                    'Good job!',
                    'Thanks!',
                    'success'
                  )
            } 
            
        } catch (error) {
            console.log("Fallo");
        }
    }
}

export const reporteRH = ( FechaReporte, AgentRH, Agent, Incidencia, Motivo, FechaInicial, FechaFinal, Horas, BonoComida ) => {
    return async( dispatch ) => {
        console.log(Date);
        try {
            const resp = await fetchConToken( 'spread/rh', { FechaReporte, AgentRH, Agent, Incidencia, Motivo, FechaInicial, FechaFinal, Horas, BonoComida }, 'POST' );
            const body = await resp.json();

            console.log(body);
            if( body.ok ) {
                Swal.fire(
                    'Good job!',
                    'Thanks!',
                    'success'
                  )
            } else {
                Swal.fire('Error', "Repeat your report, if problem persists, contact P.M. Department.", 'error');
            }
            
        } catch (error) {
      
        }
    }
}