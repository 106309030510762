import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {NavLink } from 'react-router-dom'
import { startLogout } from '../../actions/auth';
import './navbar.css'
import { uiOpenModalBug, uiOpenReporte, uiOpenReporteT } from '../../actions/ui';
import { EditModal } from './EditModal';
import { EditModalRep } from './EditModalRep';
import { EditModalRepT } from './EditModalRepT';
import Dropdown from 'react-bootstrap/Dropdown';
import NavbarSupProduct from './navbarFragments/NavbarSupProduct';

export const Navbar = () => {

    const dispatch = useDispatch();
    const { role, name } = useSelector( state => state.auth );

    const handleLogout = () => {
        dispatch( startLogout() );
    }

    const handleSearch = (e) =>{
        e.preventDefault();
        dispatch(uiOpenModalBug());
    }
    const handleSearch2 = (e) =>{
        e.preventDefault();
        dispatch(uiOpenReporte());
    }
    /*const handleSearch3 = (e) =>{
        e.preventDefault();
        dispatch(uiOpenReporteT());
    }*/

    /*window.addEventListener("close",function(){
        dispatch( startLogout() );
    });*/

    return (
        <nav className="navbar navbar-expand-sm navbar-dark color-nav">
            
            <img src='https://cdn.discordapp.com/attachments/904421426071752796/923992383358664724/61b364fd9c88962df8aeb3fc_MetaMap_logo_1.png'width="13%" height="13%" alt="CAYS" />
            
            <div className="navbar-collapse align-middle">
                <div className="navbar-nav align-items-center">
                    {/* {  (role === "Admin" || role ==="PD Admin")&&} */}                
                    <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/docs">
                        Docs
                    </NavLink>
                    <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/sr/AD">
                        General
                    </NavLink>
                    <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/search">
                        Search
                    </NavLink>
                    <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/custom">
                        Custom Doc.
                    </NavLink>
                    
                    {
                        (role==="CoordQA")&&
                        <Dropdown>
                            <Dropdown.Toggle style={{ color:'#BFCBC6', background: '#283040', borderColor: '#283040'}}>
                                QA Admin
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1"  to="/adminDoc" style={{ color: '#283040'}}>
                                    Docs (Admin)
                                </NavLink>
                                <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/adminSr" style={{ color: '#283040'}}>
                                    General (Admin)
                                </NavLink>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    
                    {
                        (role==="CoordCD")&&
                        <Dropdown>
                            <Dropdown.Toggle style={{ color:'#BFCBC6', background: '#283040', borderColor: '#283040'}}>
                                C.D. Admin
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/AdminCustom" style={{ color: '#283040'}}>
                                    C.D. (Admin)
                                </NavLink>
                                <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/adminCustomDoc" style={{ color: '#283040'}}>
                                    Documents (Admin)
                                </NavLink>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/checker">
                        MasChecker
                    </NavLink>

                    {
                        (role==="Payroll")&&
                        <Dropdown>
                            <Dropdown.Toggle style={{ color:'#BFCBC6', background: '#283040', borderColor: '#283040'}}>
                                Payroll
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ color:'#BFCBC6', background: '#283040', borderColor: '#fff'}}>
                                <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/overtimes" style={{ color:'#BFCBC6', background: '#283040', borderColor: '#283040'}}>
                                    Overtimes
                                </NavLink>  
                                <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/hrspay" style={{ color:'#BFCBC6', background: '#283040', borderColor: '#283040'}}>
                                    Hours to pay
                                </NavLink>   
                            </Dropdown.Menu>
                        </Dropdown>   
                    }
                    {
                        (role==="RH")&&
                        <Dropdown>
                            <Dropdown.Toggle style={{ color:'#BFCBC6', background: '#283040', borderColor: '#283040',  width: '100%'}}>
                                RRHH
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ color:'#BFCBC6', background: '#283040', borderColor: '#fff'}}>
                                <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/checkerAlert" style={{ color: '#BFCBC6'}}>
                                    Checker alerts
                                </NavLink>  
                                <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/users" style={{ color: '#BFCBC6'}}>
                                    Users
                                </NavLink> 
                                <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/agents" style={{ color: '#BFCBC6'}}>
                                    Agents
                                </NavLink>   
                                {/*<NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/incidents" style={{ color: '#BFCBC6'}}>
                                    Incidents
                                </NavLink>*/}  
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    
                    {
                        (role === "Productivity" && <NavbarSupProduct/>)
                    }
                    {
                        (role==="Admin")&&
                        <Dropdown>
                            <Dropdown.Toggle style={{ color:'#BFCBC6', background: '#283040', borderColor: '#283040'}}>
                                Admin
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ color:'#BFCBC6', background: '#283040', borderColor: '#fff'}}>
                                <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/users" style={{ color:'#BFCBC6', background: '#283040', borderColor: '#283040'}}>
                                    Users (Admin)
                                </NavLink>
                                <Dropdown>
                                    <Dropdown.Toggle style={{ color:'#BFCBC6', background: '#283040', borderColor: '#283040', width: '100%'}}>
                                        C.D. Admin
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/AdminCustom" style={{ color: '#283040'}}>
                                            C.D. (Admin)
                                        </NavLink>
                                        <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/adminCustomDoc" style={{ color: '#283040'}}>
                                            Documents (Admin)
                                        </NavLink>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle style={{ color:'#BFCBC6', background: '#283040', borderColor: '#283040', width: '100%'}}>
                                        Q.A. Admin
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1"  to="/adminDoc" style={{ color: '#283040'}}>
                                            Docs (Admin)
                                        </NavLink>
                                        <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/adminSr" style={{ color: '#283040'}}>
                                            General (Admin)
                                        </NavLink>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle style={{ color:'#BFCBC6', background: '#283040', borderColor: '#283040',  width: '100%'}}>
                                        Payroll
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/overtimes" style={{ color: '#283040'}}>
                                            Overtimes
                                        </NavLink>  
                                        <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/hrspay" style={{ color: '#283040'}}>
                                            Hours to pay
                                        </NavLink>   
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                <Dropdown.Toggle style={{ color:'#BFCBC6', background: '#283040', borderColor: '#283040',  width: '100%'}}>
                                    Productivity
                                </Dropdown.Toggle>
                                    <Dropdown.Menu >
                                                <Dropdown>
                                                    <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/agents" style={{ color: '#283040'}}>
                                                        Agents
                                                    </NavLink>  
                                                </Dropdown>
                                        
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle style={{ color:'#BFCBC6', background: '#283040', borderColor: '#283040',  width: '100%'}}>
                                        RRHH
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/checkerAlert" style={{ color: '#283040'}}>
                                            Checker alerts
                                        </NavLink>  
                                        <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/users" style={{ color: '#283040'}}>
                                            Users
                                        </NavLink>  
                                        <NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/agents" style={{ color: '#283040'}}>
                                            Agents
                                        </NavLink>  
                                        {/*<NavLink  activeClassName="active" className="nav-item nav-link text-center ml-1" to="/incidents" style={{ color: '#283040'}}>
                                            Incidents
                                        </NavLink>*/}    
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>
            </div>

            {/*<NavLink className="navbar-brand" to="">
                Welcome { name }
                </NavLink>*/}
            
            <p class="user"> Welcome { name } &nbsp;</p> 
            
            {/*<form onSubmit={handleSearch3}>
                    <button type="submit" className="mr-3 btn " style={{color: 'purple'}}><i class="fas fa-fish"></i></button>
            </form>*/}
            <form onSubmit={handleSearch2}>
                    <button type="submit" className="mr-2 btn " style={{color: 'purple'}}><i class="far fa-clock"></i></button>
            </form>
            
            <form onSubmit={handleSearch}>
                    <button type="submit" className="ml-2 btn " style={{color: 'purple'}}><i className="fas fa-bug"></i></button>
            </form>
            &nbsp; &nbsp; &nbsp;
            <button 
                className="btn btn-outline-danger"
                onClick={ handleLogout }>
                <i className="fas fa-sign-out-alt"></i>
                <span> Logout</span>
            </button>
            <EditModal />
            <EditModalRep />
            <EditModalRepT />
        </nav>
        
    )
}