import React from "react";
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { PaisesScreen } from "../components/pais/PaisesScreen";
import { PaisDoc } from "../components/pais/PaisDoc";
import { Navbar } from "../components/ui/Navbar";
import { SearchScreen } from "../components/search/SearchScreen";
import { RulesType } from "../components/sr/RulesType";
import { Usuarios } from "../components/adminUsers/usuario";
import { NewUser } from "../components/adminUsers/newUser";
import { AdminSr } from "../components/adminSr/adminSr";
import { AdminDoc } from "../components/adminDocs/adminDoc";
import { SearchCustom } from "../components/customdocs/SearchCustom";
import { Customs } from "../components/customdocs/adminCustom";
import { CustomDocs } from "../components/customdocs/customDocs";
import { AdminDocCustom } from "../components/customdocs/adminDoc";
import { Checker } from "../components/maschecker/checker";
import { Overtimes } from "../components/payroll/overtimes";
import { HoursPay } from "../components/payroll/hrspay";
import { CheckAlerts } from "../components/rrhh/checkAlerts";
import { Agents } from "../components/rrhh/agents";
//import { ReportRH } from "../components/rrhh/reportRH";

export const DashboardRoutes = () =>{
  document.body.style.backgroundColor = "#283040";
  return(
    <>
    <Navbar/>
      <div>
        <Switch >
            <Route exact path="/docs" component={PaisesScreen} />
            <Route exact path="/pais/:paisId/:type" component={PaisDoc} />
            <Route exact path="/search" component={SearchScreen} />
            <Route exact path="/custom" component={SearchCustom} />
            <Route exact path="/sr/:type" component={RulesType} />
            <Route exact path="/users" component={Usuarios} />
            <Route exact path="/users/new" component={NewUser} />
            <Route exact path="/adminDoc" component={AdminDoc} />
            <Route exact path="/adminSr" component={AdminSr} />
            <Route exact path="/AdminCustom" component={Customs} />
            <Route exact path="/customDocs/:webhook/:type" component={CustomDocs} />
            <Route exact path="/adminCustomDoc" component={AdminDocCustom} />
            <Route exact path="/checker" component={Checker} />
            <Route exact path="/overtimes" component={Overtimes} />
            <Route exact path="/hrspay" component={HoursPay} />
            <Route exact path="/checkerAlert" component={CheckAlerts} />
            <Route exact path="/agents" component={Agents} />
            {/*<Route exact path="/incidents" component={ReportRH} />*/}
            <Redirect to ="/checker" /> {/*Pagina por defecto*/}
        </Switch>
      </div>
    </>
  )
}