import { types } from "../types/types";

const initialState = {
    modalOpenDoc: false,
    modalOpenBug: false,
    modalOpenReporte: false,
    modalOpenReporteT: false,
    // ADD BY MAYRA---------------------------------------------
    modalErizo: false,
    modalMemes: false,
    modalSpecial: false,
    modalObsolete: false,
    // Add & Modified by Yaelo
    modalVideos: false,
    modalBonos: false,
    modalEncuestas: false,
}



export const uiReducer = ( state = initialState, action ) => {

    switch ( action.type ) {
        case types.uiOpenModalDoc:
            return {
                ...state,
                modalOpenDoc: true
            }

        case types.uiCloseModalDoc:
            return {
                ...state,
                modalOpenDoc: false
            }
        case types.uiOpenModalBug:
            return {
                ...state,
                modalOpenBug: true
            }

        case types.uiCloseModalBug:
            return {
                ...state,
                modalOpenBug: false
            }
        case types.uiOpenReporte:
            return {
                ...state,
                modalOpenReporte: true
            }

        case types.uiCloseReporte:
            return {
                ...state,
                modalOpenReporte: false
            }
        case types.uiOpenReporteT:
            return {
                ...state,
                modalOpenReporteT: true
            }

        case types.uiCloseReporteT:
            return {
                ...state,
                modalOpenReporteT: false
            }
// ADD BY MAYRA---------------------------------------------
            case types.uiOpenModalErizo:
                return {
                    ...state,
                    modalErizo: true
                }
    
            case types.uiCloseModalErizo:
                return {
                    ...state,
                    modalErizo: false
                }
            case types.uiOpenModalMemes:
                return {
                        ...state,
                        modalMemes: true
                    }
        
            case types.uiCloseModalMemes:
                return {
                        ...state,
                        modalMemes: false
                    }
            case types.uiOpenModalSpecialRules:
                return {
                            ...state,
                            modalSpecial: true
                    }
            
            case types.uiCloseModalSpecialRules:
                return {
                            ...state,
                            modalSpecial: false
                    }
            case types.uiOpenModalObsoleteRules:
                return {
                            ...state,
                            modalObsolete: true
                    }
            
            case types.uiCloseModalObsoleteRules:
                return {
                            ...state,
                            modalObsolete: false
                    }

//Add & Modified by Yaelo
            case types.uiOpenModalVideos:
                return{
                    ...state,
                    modalVideos: true
                }

            case types.uiCloseModalVideos:
                return{
                    ...state,
                    modalVideos: false
                }
            
            case types.uiOpenModalBonos:
                return{
                    ...state,
                modalBonos: true
                }

            case types.uiCloseModalBonos:
                return{
                    ...state,
                    modalBonos: false
                }
            
                case types.uiOpenModalEncuestas:
                    return{
                        ...state,
                    modalEncuestas: true
                    }
    
                case types.uiCloseModalEncuestas:
                    return{
                        ...state,
                        modalEncuestas: false
                    }

        default:
            return state;
    }


}