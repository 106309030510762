import moment from 'moment';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { reporteT } from '../../actions/auth';
import { uiCloseReporteT } from '../../actions/ui';
import "./modalStyle.css"

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  Modal.setAppElement('#root');
  let initEvent = {
    comment: null,
    type: null,
    job: null,
}
    
    
export const EditModalRepT = () => {
    const dispatch = useDispatch();

    let usuario = useSelector(state => state.auth);
    let modalOpenReporteT = useSelector(state => state.ui.modalOpenReporteT);
    const [formValues, setFormValues] = useState( initEvent );

    let {job, type} = formValues;

    

    const closeModal = () =>{
        dispatch(uiCloseReporteT());
    }

    const handleSearch = (e) => {
        e.preventDefault();
        let fecha = moment().tz("Asia/Calcutta").format("MM/DD/YYYY");
        

        if(job==="" || job=== undefined || job=== null || job.length <= 10){
 
            Swal.fire({
                icon: 'error',
                title: 'Incorrect format',
                text: 'Please, Use the correct format to report!'
              })
        }else{
            dispatch(reporteT(fecha, usuario.name, job, type));
        dispatch(uiCloseReporteT());
        }
    }
    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }

    return (
        <Modal
            isOpen={modalOpenReporteT}
            //onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            className="modal"
            overlayClassName="modal-fondo">
            <div>
                <h1 className="text-center">AD Reports</h1>
                <hr />
                <form onSubmit={handleSearch}>
                    <label>Job ID</label>
                    <input 
                        type="text" 
                        placeholder="61c5f307108063001c1ef5e9" 
                        className="form-control" 
                        name="job"
                        autoComplete="off"
                        value={job}
                        onChange={handleInputChange}
                    ></input>
                    <label>Fraud type</label>
                        <select className="form-control" name="type" onChange={ handleInputChange }>
                            <option selected>Select type</option>
                            <option value="DIFFERENT FRONT AND BACK"> DIFFERENT FRONT AND BACK </option>
                            <option value="DIGITAL PHOTO REPLACEMENT"> DIGITAL PHOTO REPLACEMENT </option>
                            <option value="FAKE IMAGE"> FAKE IMAGE </option>
                            <option value="TEXT REPLACEMENT"> TEXT REPLACEMENT </option>
                            <option value="MANUAL PHOTO REPLACEMENT"> MANUAL PHOTO REPLACEMENT </option>
                        </select>
                    <button type="submit" className="btn mt-2 btn-block btn-outline-primary">Send</button>
                </form>
            </div>
        </Modal>
    )
}
